<mat-list>
  <div *ngFor="let menu of _sidebar.menu">
    <mat-list-item routerLink="home" routerLinkActive="active">
      <mat-icon class="{{ menu.icono }}">{{ menu.icono }}</mat-icon>
      {{ menu.titulo }}
    </mat-list-item>
    <div class="tws-mt-16">
      <div *ngFor="let submenu of menu.submenu">
        <mat-list-item
          class="ui-element tws-cursor-pointer hover:!tws-text-white hover:tws-bg-theme-tertiary"
          routerLink="{{ submenu.url }}"
          routerLinkActive="active"
        >
          <div
            class="tws-flex tws-justify-start tws-items-center hover:tws-text-white tws-cursor-pointer"
          >
            <mat-icon class="{{ submenu.icono }}">{{ submenu.icono }}</mat-icon>
            <div>{{ submenu.titulo }}</div>
          </div>
        </mat-list-item>
      </div>
    </div>
  </div>
</mat-list>
