<app-layout>
  <div class="tws-max-w-full">
    <div class="tws-w-full">
      <div class="card tws-py-8 tws-min-h-screen">
        <div class="card-body" id="seccionPagar">
          <h4 class="card-title tws-text-lg">Lista de Pedidos</h4>
          <h6 class="card-subtitle tws-text-md">
            Pantalla que se visualiza los pedidos
          </h6>

          <div class="tws-mt-5">
            <mat-expansion-panel
              [expanded]="panelOpenState === true"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h4 class="card-title">Lista de Pedidos</h4>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div
                class="tws-grid tws-grid-cols-1 md:tws-grid-cols-2 lg:tws-grid-cols-5 tws-gap-3"
              >
                <div>
                  <mat-form-field
                    appearance="fill"
                    class="tws-max-w-full tws-w-full"
                  >
                    <mat-label>Pedido</mat-label>
                    <input matInput [formControl]="numPedido" />
                    <mat-error *ngIf="numPedido.hasError('required')">
                      Valor requerido
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="">
                  <mat-form-field
                    appearance="fill"
                    class="tws-max-w-full tws-w-full"
                  >
                    <mat-label>Fecha Desde</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker"
                      [formControl]="fechaDesde"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="">
                  <mat-form-field
                    appearance="fill"
                    class="tws-max-w-full tws-w-full"
                  >
                    <mat-label>Fecha Hasta</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker1"
                      [formControl]="fechaHasta"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker1"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="tws-flex tws-flex-wrap">
                <div class="tws-my-2">
                  <mat-checkbox
                    color="primary"
                    class="example-margin"
                    [formControl]="tiposPedidos"
                    >Pendientes de entrega</mat-checkbox
                  >
                </div>
              </div>

              <div class="tws-flex tws-justify-between">
                <div
                  class="tws-mt-4 tws-mb-2 lg:tws-w-5/12 md:tws-w-6/12 tws-w-11/12"
                >
                  <button
                    mat-raised-button
                    color="accent"
                    (click)="buscar()"
                    class="!tws-py-6 tws-mr-4 tws-mt-2"
                  >
                    Buscar
                  </button>
                  <button
                    mat-raised-button
                    color="accent"
                    (click)="descargarPdf347()"
                    class="!tws-py-6 tws-mr-4 tws-mt-2"
                  >
                    Pdf 347
                  </button>
                  <button
                    mat-raised-button
                    color="accent"
                    (click)="descargarXlsx347()"
                    class="!tws-py-6 tws-mr-4 tws-mt-2"
                  >
                    Xlsx 347
                  </button>
                  <button
                    mat-raised-button
                    color="accent"
                    (click)="descargarPdf347Detallado()"
                    class="!tws-py-6 tws-mr-4 tws-mt-2"
                  >
                    Pdf 347 Detallado
                  </button>

                  <button
                    mat-raised-button
                    color="accent"
                    (click)="descargarXlsx347Detallado()"
                    class="!tws-py-6 tws-mt-2"
                  >
                    Xlsx 347 Detallado
                  </button>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
          <div *ngIf="cargando" class="tws-flex tws-justify-center tws-mt-12">
            <mat-spinner
              strokeWidth="4"
              class="!tws-text-blue-400"
            ></mat-spinner>
          </div>
          <div
            *ngIf="pedidos.length === 0 && cargando == false"
            class="tws-flex tws-justify-center tws-mt-12"
          >
            <div>No se ha encontrado resultados</div>
          </div>
          <div
            *ngIf="pedidos.length !== 0 && cargando == false"
            class="tws-grid tws-mt-12 tws-grid-cols-1 lg:tws-grid-cols-4 xxl:tws-grid-cols-5 md:tws-grid-cols-2 tws-gap-4"
          >
            <div *ngFor="let cobro of pedidos"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-layout>
