import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { FormControl, Validators } from '@angular/forms';
import { FacturaSeleccionadaModel } from 'src/app/models/facturaSelecionada.model';
import { CobroService } from 'src/app/services/cobro/cobro.service';
import { Alerta } from 'src/app/mensajes/helperAlerta';
import { Mensaje } from 'src/app/mensajes/mensajes';
import { CobroModel } from 'src/app/models/cobro.model';

@Component({
  selector: 'app-tarjetaefectuados',
  templateUrl: './tarjeta-efectuados.component.html',
  styleUrls: ['./tarjeta-efectuados.component.css'],
})
export class TarjetaEfectuadosComponent implements OnInit {
  @Input() cobro: CobroModel;

  @Output() seleccion = new EventEmitter<FacturaSeleccionadaModel>();
  @Output() urlFirma = new EventEmitter<string>();
  //@Input() selecionarFactura: (factura: FacturaSeleccionadaModel, agregar: boolean) => string;

  seleccionarControl: FormControl;
  private _alerta: Alerta;
  private _mensaje: Mensaje;

  constructor(private _cobrosService: CobroService) {}

  ngOnInit() {
    this._mensaje = new Mensaje();
    this._alerta = new Alerta();
    this.seleccionarControl = new FormControl(false);

    this.seleccionarControl.valueChanges.subscribe((value) => {
      if (value) {
        this.seleccion.emit({
          factura: this.cobro.factura,
          noEntrada: this.cobro.noEntrada,
          seleccionada: true,
        });
      } else
        this.seleccion.emit({
          factura: this.cobro.factura,
          noEntrada: this.cobro.noEntrada,
          seleccionada: false,
        });
    });
  }

  getValorPagado() {
    //return UtilsService.formatMoney(this.getPagado(parseFloat(this.cobro.factura)))
  }

  getImporte() {
    return UtilsService.formatMoney(this.cobro.importePagado);
  }

  fechaCobro() {
    return UtilsService.formatDate(this.cobro.fechaCobro);
  }

  fechaFactura() {
    return UtilsService.formatDate(this.cobro.fechaFactura);
  }

  importeFactura() {
    return UtilsService.formatMoney(this.cobro.importe);
  }

  replaceAfterTempWithEncoded = (url: string) => {
    const tempIndex = url.indexOf('/temp/') + 6; // add 6 to exclude '/temp/' itself

    const beforeTemp = url.substring(0, tempIndex);

    const afterTemp = url.substring(tempIndex);
    const endIndex = afterTemp.indexOf('?'); // find the index of the next question mark character
    const pdfString = afterTemp.substring(0, endIndex); // extract the string up to the question mark

    const encodedPdfString = btoa(pdfString); // encode the string in base64

    const newAfterTemp = encodedPdfString + afterTemp.substring(endIndex); // replace the pdfString with the encoded version
    return beforeTemp + newAfterTemp;
  };

  descargarPdf() {
    this._cobrosService.obtenerUrlFactura(this.cobro.pedido).subscribe({
      next: (data: any) => {
        if (data.existeError || !data.data)
          this._alerta.mostrarAlerta(
            this._mensaje.errorNoPdfFactura,
            this._mensaje.alertError
          );
        else {
          window.open(
            this.replaceAfterTempWithEncoded(data.data.url),
            '_blank'
          );
        }
      },
      error: (error: any) => {
        if (error.error.errors)
          this._alerta.mostrarAlerta(
            Object.values(error.error.errors).join(' \n'),
            'error',
            ''
          );
        else this._alerta.mostrarAlerta(error.error.error, 'error', '');
      },
      complete: () => {},
    });
  }

  descargarFirma() {
    this._cobrosService.obtenerUrlFirma(this.cobro.pedido).subscribe({
      next: (data: any) => {
        if (data.existeError || !data.data)
          this._alerta.mostrarAlerta(
            this._mensaje.errorNoFirmaFactura,
            this._mensaje.alertError
          );
        else {
          if (data.data.url.includes('.pdf')) {
            window.open(
              this.replaceAfterTempWithEncoded(data.data.url),
              '_blank'
            );
          } else this.urlFirma.emit(data.data.url);
        }
      },
      error: (error: any) => {
        if (error.error.errors)
          this._alerta.mostrarAlerta(
            Object.values(error.error.errors).join(' \n'),
            'error',
            ''
          );
        else this._alerta.mostrarAlerta(error.error.error, 'error', '');
      },
      complete: () => {},
    });
  }
}
