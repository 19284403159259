<app-header
  (isShowSidebar)="sidenav.toggle($event)"
  [isMenuOpened]="isShowSidebar"
></app-header>

<mat-sidenav-container class="layout">
  <mat-sidenav
    #sidenav
    mode="side"
    [(opened)]="isShowSidebar"
    class="layout-sidebar tws-bg-theme-primary"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    fixedInViewport="true"
    fixedTopGap="0"
    fixedBottomGap="0"
  >
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content
    class="layout-content tws-mt-20 tws-w-auto tws-max-w-auto tws-overflow-hidden"
  >
    <ng-content class="tws-w-full"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
