
export class CobroModel {
  fechaEnvio: string;
  tipoCobro: number;
  pedido: string;
  factura: string;
  cliente: number;
  nombreCliente: string;
  fechaFactura: string;
  fechaVencimiento: string;
  fechaCobro: string;
  importe: number;
  representante: string;
  importePagado: number;
  valorPagado: number;
  codigoTipo: string;
  remesar: number;
  estado: string;
  noCobro: number;
  noEntrada: number;
  aRemesar: boolean
}
