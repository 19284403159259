<section
  id="wrapper"
  class="login-register tws-min-h-screen login-sidebar tws-flex tws-justify-center tws-items-center"
  style="
    background-image: url(assets/images/background/login-register.jpg);
    background-position: bottom;
  "
>
  <div class="tws-p-6 card">
    <div class="card-body">
      <div class="tws-flex tws-flex-col tws-items-center">
        <div>
          <img src="assets/images/logo-icon.png" alt="Home" />
        </div>
        <div class="tws-text-xl tws-font-extrabold tws-mt-5">SURCANAL</div>
      </div>
      <div class="tws-my-4 tws-text-center">
        <h3 class="box-title m-t-40 m-b-0 tws-text-lg">Registro</h3>
        <div class="tws-text-md">Crear usuario para la aplicacion</div>
      </div>
      <mat-horizontal-stepper #stepper>
        <mat-step>
          <form>
            <ng-template matStepLabel>DATOS</ng-template>

            <div class="form-group m-t-20">
              <div class="col-xs-12">
                <input
                  class="form-control"
                  type="text"
                  required=""
                  placeholder="Escriba su DNI / NIF"
                  [(ngModel)]="usuario.dni"
                  name="dni"
                />
              </div>
            </div>

            <div class="form-group m-t-20">
              <div class="col-xs-12">
                <input
                  class="form-control"
                  type="text"
                  required=""
                  placeholder="Escriba su Cod. de cliente"
                  [(ngModel)]="usuario.id"
                  name="id"
                  maxlength="5"
                />
              </div>
            </div>

            <div>
              <button
                name="siguiente"
                class="btn btn-info btn-lg btn-block text-uppercase btn-rounded"
                (click)="validaDniExistente(stepper)"
                [disabled]="bloquearBotonRegistrar"
              >
                Next
              </button>

              <div class="form-group m-b-0 tws-mt-5">
                <div class="col-sm-12 text-center">
                  <a routerLink="/" class="txt1"> Regresar a Login </a>
                </div>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step>
          <form>
            <ng-template matStepLabel>COD</ng-template>
            <label
              >Escribe el siguiente c&oacute;digo:
              <b>{{ llaveWhatsapp }} </b></label
            >
            <div class="col-md-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  required
                  placeholder="Codigo"
                  [(ngModel)]="usuario.codigoWhatsapp"
                  name="codigoWhatsapp"
                />
              </div>
            </div>

            <div>
              <button
                class="btn btn-info btn-lg btn-block text-uppercase btn-rounded"
                (click)="validacionCodigoWhatsapp(stepper)"
                [disabled]="bloquearBotonRegistrar"
              >
                Next
              </button>
              <button
                matStepperPrevious
                [disabled]="bloquearBotonRegistrar"
                class="btn btn-danger btn-lg btn-block text-uppercase btn-rounded"
              >
                Back
              </button>

              <div class="form-group m-b-0 tws-mt-5">
                <div class="col-sm-12 text-center">
                  <a routerLink="/" class="txt1"> Regresar a Login </a>
                </div>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>FIN</ng-template>

          <div class="form-group">
            <div class="col-xs-12">
              <input
                class="form-control"
                type="password"
                required
                placeholder="Password"
                name="password"
                [(ngModel)]="usuario.pass"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <input
                class="form-control"
                type="password"
                required
                placeholder="Confirmar Password"
                name="confirmarPassword"
                [(ngModel)]="usuario.repitePass"
              />
            </div>
          </div>

          <div>
            <button
              (click)="registrar()"
              class="btn btn-info btn-lg btn-block text-uppercase btn-rounded"
              [disabled]="bloquearBotonRegistrar"
            >
              Registrar
            </button>
            <button
              matStepperPrevious
              [disabled]="bloquearBotonRegistrar"
              class="btn btn-danger btn-lg btn-block text-uppercase btn-rounded"
            >
              Back
            </button>

            <div class="form-group m-b-0 tws-mt-5">
              <div class="col-sm-12 text-center">
                <a routerLink="/" class="txt1"> Regresar a Login </a>
              </div>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</section>
