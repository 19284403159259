import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuarioModel } from '../../models/usuario.model';
import { Mensaje } from '../../mensajes/mensajes';
import { Alerta } from '../../mensajes/helperAlerta';
import { LoginService } from '../../services/login.service';
import { CookieService } from 'ngx-cookie-service';
import { PusherService } from '../../services/pusher.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  datosLogin: UsuarioModel;
  mensaje: Mensaje;
  alerta: Alerta;
  forma: FormGroup;
  bloquearBoton: boolean = false;
  hide = true;
  cargando: boolean = false;

  constructor(
    public router: Router,
    private pusherService: PusherService,
    private auth: LoginService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.datosLogin = new UsuarioModel();
    this.mensaje = new Mensaje();
    this.alerta = new Alerta();

    this.forma = new FormGroup({
      usuario: new FormControl(null, Validators.required),
      pass: new FormControl(null, Validators.required),
      guardarSession: new FormControl(true),
    });

    if (this.auth.estaLogueado()) this.router.navigate(['/cobros']);
    this.auth.cargando.subscribe((cargando) => (this.cargando = cargando));
  }

  onSubmit() {
    this.bloquearBoton = true;
    this.datosLogin.usuario = this.forma.value.usuario;
    this.datosLogin.pass = this.forma.value.pass;
    this.cargando = true;
    this.auth.login(this.datosLogin).subscribe({
      next: (data: any) => {
        this.bloquearBoton = false;
        if (data.existeError)
          this.alerta.mostrarAlerta(
            data.mensaje,
            this.mensaje.alertAdvertencia
          );
        else {
          let periodo = 2;
          if (this.forma.value.guardarSession == false) periodo = 0;

          this.cookieService.set('id', data.data.informacion.id, periodo);
          this.cookieService.set('dni', data.data.informacion.dni, periodo);
          this.cookieService.set('token', data.data.informacion.token, periodo);
          this.cookieService.set(
            'beartoken',
            data.data.informacion.token,
            periodo
          );
          this.cookieService.set(
            'nombre',
            data.data.informacion.nombre,
            periodo
          );
          this.cookieService.set(
            'direcion',
            data.data.informacion.direcion,
            periodo
          );
          this.cookieService.set('movil', data.data.informacion.movil, periodo);
          this.cookieService.set(
            'ciudad',
            data.data.informacion.ciudad,
            periodo
          );
          this.cookieService.set(
            'vendedor',
            data.data.informacion.vendedor,
            periodo
          );

          this.pusherService.subscribeToNotifications();

          this.router.navigate(['/cobros']);
        }
      },
      error: (error: any) => {
        if (error.error.errors)
          this.alerta.mostrarAlerta(
            Object.values(error.error.errors).join(' \n'),
            'error',
            ''
          );
        else this.alerta.mostrarAlerta(error.error.error, 'error', '');
        this.cargando = false;
        this.bloquearBoton = false;
        this.forma.reset();
        this.datosLogin.usuario = this.forma.value.usuario;
        this.datosLogin.pass = this.forma.value.pass;
      },
      complete: () => {
        this.cargando = false;
        this.bloquearBoton = false;
        this.forma.reset();
        this.datosLogin.usuario = this.forma.value.usuario;
        this.datosLogin.pass = this.forma.value.pass;
      },
    });
  }
}
