import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { UsuarioModel } from '../../models/usuario.model';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BuzonService {
  private url = '';
  private _cargando = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.url = environment.baseUrlApi;
  }

  listarBuzon({ codUsuario, fecDesde, fecHasta }) {
    return this.http.get(`${this.url}avisos-cliente/${fecDesde}/${fecHasta}`);
  }

  get cargando() {
    return this._cargando.asObservable();
  }
}
