import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { CobroService } from 'src/app/services/cobro/cobro.service';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { FacturaModel } from 'src/app/models/factura.model';
import { Mensaje } from 'src/app/mensajes/mensajes';
import { Alerta } from 'src/app/mensajes/helperAlerta';
import { MatDialog } from '@angular/material/dialog';
import { TarjetacobroComponent } from 'src/app/components/tarjetacobro/tarjetacobro.component';
import { FormControl, Validators } from '@angular/forms';
import _ from 'lodash';
import { LoginService } from 'src/app/services/login.service';
import { FacturaPendienteModel } from 'src/app/models/facturaPendiente.model';
import { FacturaPagarModel } from 'src/app/models/facturaPagar.model';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { FacturaSeleccionadaModel } from 'src/app/models/facturaSelecionada.model';
import { PagarCobroComponent } from 'src/app/components/pagar-cobro/pagar-cobro.component';
import { TipoCobro } from 'src/app/enums/tipoCobro.enum';
import { Router } from '@angular/router';
import { ModalFirmaComponent } from 'src/app/components/modal-firma/modal-firma.component';
import { ViewportScroller } from '@angular/common';
import { ProcesarCobroPasarelaComponent } from 'src/app/components/procesar-cobro-pasarela/procesar-cobro-pasarela.component';

@Component({
  selector: 'app-cobros',
  templateUrl: './cobros.component.html',
  styles: [],
})
export class CobrosComponent implements OnInit {
  _usuario: UsuarioModel;
  private _alerta: Alerta;
  private _mensaje: Mensaje;
  _numFactura: number;
  _importePorCobrar: number;
  _importeCobrado: number;
  _importeCobradoTotal: number;
  _importePendiente: number;
  theCheckbox: boolean;
  _todo: boolean = false;
  _bloquearBoton: boolean = false;
  _contadorAbonosSeleccionados: number = 0;
  _listaAbonos: Array<FacturaModel>;
  _maximoAbonosCobros: Number = 100000000000000;
  animal: string;
  name: string;
  cobrosPendientes: Array<FacturaPendienteModel>;
  cobrosPagar: Array<FacturaPagarModel>;
  facturasSeleccionadas: Array<FacturaSeleccionadaModel>;
  existeVencidos: boolean = false;
  panelOpenState: boolean = true;
  panelCalcularState: boolean = false;
  llamadoCalcular: boolean = false;
  metodoPago: TipoCobro;
  existeErrorSeleccion: boolean = false;
  cargando: boolean = false;
  calcularInputControl: FormControl;

  _optionsTypePay = [
    { value: '0', label: 'Remesa' },
    { value: '1', label: 'Transferencia' },
    { value: '2', label: 'BIZUM' },
  ];

  @ViewChild(TarjetacobroComponent)
  componentProperty!: TarjetacobroComponent;

  @ViewChild(ModalFirmaComponent)
  modalFirma!: ModalFirmaComponent;

  @ViewChild('seccionPagar', { static: true })
  seccionPagar: ElementRef;

  constructor(
    private _cobrosService: CobroService,
    public dialog: MatDialog,
    private router: Router,
    private scroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    this._mensaje = new Mensaje();
    this._alerta = new Alerta();

    this.calcularInputControl = new FormControl('', [
      Validators.required,
      Validators.min(0),
    ]);

    this._cobrosService.establecerCobrosPendientes();
    this._cobrosService.cobrosPendientes.subscribe(
      (nuevosCobrosP) => (this.cobrosPendientes = nuevosCobrosP)
    );
    this._cobrosService.cobrosPagar.subscribe(
      (cobros) => (this.cobrosPagar = cobros)
    );
    this._cobrosService.facturasSeleccionadas.subscribe(
      (seleccion) => (this.facturasSeleccionadas = seleccion)
    );
    this._cobrosService.existeVencidas.subscribe(
      (valor) => (this.existeVencidos = valor)
    );
    this._cobrosService.cargando.subscribe((valor) => (this.cargando = valor));
    this._cobrosService.error.subscribe((error) => {
      if (error) this._alerta.mostrarAlerta(error, 'error', '');
    });
  }

  formatMoney(val: any) {
    return UtilsService.formatMoney(val);
  }

  getTotalFacturasPagar() {
    return this.cobrosPagar.reduce((accumulator, cobro) => accumulator + 1, 0);
  }

  getTotalFacturas() {
    return this.cobrosPendientes.reduce(
      (accumulator: number, cobro) => accumulator + 1,
      0
    );
  }

  getTotalImporte() {
    return this.cobrosPendientes.reduce(
      (accumulator: number, cobro) => accumulator + cobro.importe,
      0
    );
  }

  getImporteCobrado() {
    return this.cobrosPagar.reduce(
      (accumulator: number, cobro) => accumulator + cobro.importe,
      0
    );
  }

  irApagar() {
    //this.seccionPagar.scrollIntoView({ behavior: 'smooth' });
    this.scroller.scrollToAnchor('seccionPagar');
  }

  cobrosEfectuados() {
    this.router.navigate(['cobros/efectuados']);
  }

  calcular() {
    this.llamadoCalcular = true;
    let sel: Array<FacturaPagarModel> = [];
    if (this.calcularInputControl.value <= 0) {
      this._alerta.mostrarAlerta(
        'Valor tiene que ser mayor de cero',
        'error',
        ''
      );
    } else if (this.calcularInputControl.value > this.getTotalImporte()) {
      this._alerta.mostrarAlerta('Valor excede el importe total', 'error', '');
    } else {
      let cant2 = this.calcularInputControl.value;
      for (const cobro of this.cobrosPendientes) {
        if (cant2 == 0) break;
        let imp = cobro.importe;
        if (cant2 > imp) {
          let fpm = new FacturaPagarModel();
          fpm.factura = cobro.factura;
          fpm.importe = imp;
          fpm.remesando = cobro.aRemesar;
          sel.push(fpm);
          cant2 -= imp;
        } else {
          let fpm = new FacturaPagarModel();
          fpm.factura = cobro.factura;
          fpm.importe = cant2;
          fpm.remesando = cobro.aRemesar;
          sel.push(fpm);
          cant2 = 0;
        }
      }
    }
    this._cobrosService.establecerCobrosPagar(sel);
    this._cobrosService.estabalecerFacturasSeleccionadas(
      sel.map((s) => {
        let factSelec = new FacturaSeleccionadaModel();
        factSelec.factura = s.factura;
        return factSelec;
      })
    );
  }

  checkOrden(seleccion: Array<FacturaSeleccionadaModel>) {
    if (seleccion.length == 0) return false;
    let numSelect = seleccion.length;
    let penn = {};
    let ult = this.cobrosPendientes.find((el) => {
      if (el.factura == seleccion[numSelect - 1].factura) return el;
    });
    if (ult.importe < 0) return false;
    let fechaUlt = new Date(ult.fechaVencimiento);
    let filtrados = this.cobrosPendientes.filter((el) => {
      let fechaEl = new Date(el.fechaVencimiento);
      if (
        fechaUlt > fechaEl &&
        el.importe > 0 &&
        !el.conError &&
        !seleccion.find((sel) => sel.factura == el.factura)
      )
        return el;
    });
    return filtrados.length;
  }

  verFirma(urlFirma: string) {
    const dialogRef = this.dialog.open(ModalFirmaComponent, {
      width: '1000px',
      height: '1000px',
      data: { urlFirma: urlFirma },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  seleccionarFactura(factura: FacturaSeleccionadaModel) {
    let seleccionProvi: Array<FacturaSeleccionadaModel>;
    if (!factura.seleccionada) {
      seleccionProvi = this.facturasSeleccionadas.filter(
        (el) => el.factura != factura.factura
      );
      this._cobrosService.estabalecerFacturasSeleccionadas(seleccionProvi);
    } else seleccionProvi = [...this.facturasSeleccionadas, factura];
    if (!this.llamadoCalcular) {
      if (this.checkOrden(seleccionProvi) && this.existeVencidos) {
        this._cobrosService.establecerExisteErrorSeleccion(true);
        this._alerta.mostrarAlerta(
          'Los pagos deben realizarse por antiguedad de factura',
          'error',
          ''
        );
      } else {
        let sel: Array<FacturaPagarModel> = [];
        for (const cobro of this.cobrosPendientes) {
          let imp = cobro.importe;
          for (const selVal of seleccionProvi) {
            if (selVal.factura == cobro.factura) {
              let factP = new FacturaPagarModel();
              factP.factura = cobro.factura;
              factP.importe = imp;
              factP.remesando = cobro.aRemesar;
              sel.push(factP);
            }
          }
        }
        this._cobrosService.establecerCobrosPagar(sel);
        this._cobrosService.estabalecerFacturasSeleccionadas(seleccionProvi);
      }
    } else {
      this._cobrosService.estabalecerFacturasSeleccionadas(seleccionProvi);
      this.llamadoCalcular = false;
    }
  }

  pagar() {
    this.cargando = true;
    if (UtilsService.compareFloats(this.getImporteCobrado(), 0) === false) {
      this._alerta.mostrarAlerta(
        'El importe a cobrar tiene que ser positivo',
        'error',
        ''
      );
      this.cargando = false;
    } else if (
      UtilsService.compareFloats(this.getImporteCobrado(), 0) === 1 &&
      this.contieneAbonoFactura()
    ) {
      let mensaje = '';
      this.cobrosPagar.map((cobro) => {
        mensaje += ', ' + cobro.factura;
      });
      mensaje =
        'Se realizara una compensacion entre los importes pendientes de las facturas' +
        mensaje;
      let titulo = 'Compensar Importes';
      this._alerta
        .confirmarAlerta(titulo, mensaje, this._mensaje.alertAdvertencia)
        .then((result: any) => {
          if (result.value) {
            this.metodoPago = TipoCobro.COMPENSACION;
            this._cobrosService
              .registrarCobro({
                metodoPago: this.metodoPago,
                noCheque: '',
                banco: '',
                tarjeta: '',
                cobros: this.cobrosPagar,
                fechaVencimiento: '',
              })
              .subscribe({
                next: (data: any) => {
                  if (data.existeError) {
                    this._alerta.mostrarAlerta(data.mensaje, 'error', '');
                  } else {
                    this._alerta.mostrarAlerta(
                      `Cobro ${data.data} registrado correctamente`,
                      'success',
                      ''
                    );
                    this._cobrosService.establecerCobrosPendientes();
                    this._cobrosService.estabalecerFacturasSeleccionadas([]);
                    this._cobrosService.establecerCobrosPagar([]);
                    this._cobrosService.establecerExisteErrorSeleccion(false);
                  }
                },
                error: (err: any) => {
                  if (err.error.errors)
                    this._alerta.mostrarAlerta(
                      Object.values(err.error.errors).join(' \n'),
                      'error',
                      ''
                    );
                  else this._alerta.mostrarAlerta(err.error.error, 'error', '');
                  this.cargando = false;
                },
                complete: () => {
                  this.cargando = false;
                },
              });
          }
        })
        .finally(() => {
          this._cobrosService.establecerCobrosPendientes();
          this._cobrosService.estabalecerFacturasSeleccionadas([]);
          this._cobrosService.establecerCobrosPagar([]);
          this._cobrosService.establecerExisteErrorSeleccion(false);
        });
    } else if (
      UtilsService.compareFloats(this.getImporteCobrado(), 0) === true
    ) {
      const dialogRef = this.dialog.open(PagarCobroComponent, {
        data: { importe: this.getImporteCobrado(), cobros: this.cobrosPagar },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.metodoPago = result.result.metodoPago;
          this.procesarPago();
        } else {
          this.cargando = false;
          this._cobrosService.establecerCobrosPendientes();
          this._cobrosService.estabalecerFacturasSeleccionadas([]);
          this._cobrosService.establecerCobrosPagar([]);
          this._cobrosService.establecerExisteErrorSeleccion(false);
        }
      });
    } else {
      this._alerta.mostrarAlerta('Establece el importe a pagar', 'Error', '');
      this.cargando = false;
    }
  }

  procesarPago() {
    if (this.metodoPago == null) {
      this._alerta.mostrarAlerta('Selecciona el metodo de pago', 'Error', '');
      this.cargando = false;
    } else {
      this._cobrosService
        .registrarCobro({
          metodoPago: this.metodoPago,
          noCheque: '',
          banco: '',
          tarjeta: '',
          cobros: this.cobrosPagar,
          fechaVencimiento: '',
        })
        .subscribe({
          next: (data: any) => {
            if (data.existeError) {
              this._alerta.mostrarAlerta(data.mensaje, 'error', '');
            } else {
              if (this.metodoPago != TipoCobro.PASARELA) {
                this._alerta.mostrarAlerta(
                  `Cobro ${data.data} registrado correctamente`,
                  'success',
                  ''
                );
              }
              this._cobrosService.establecerCobrosPendientes();
              this._cobrosService.estabalecerFacturasSeleccionadas([]);
              this._cobrosService.establecerCobrosPagar([]);
              this._cobrosService.establecerExisteErrorSeleccion(false);
            }
          },
          error: (err: any) => {
            this.cargando = false;
            this._cobrosService.establecerCobrosPendientes();
            this._cobrosService.estabalecerFacturasSeleccionadas([]);
            this._cobrosService.establecerCobrosPagar([]);
            this._cobrosService.establecerExisteErrorSeleccion(false);
            if (err.error.errors)
              this._alerta.mostrarAlerta(
                Object.values(err.error.errors).join(' \n'),
                'error',
                ''
              );
            else this._alerta.mostrarAlerta(err.error.error, 'error', '');
            this.cargando = false;
          },
          complete: () => {
            this.cargando = false;
            this._cobrosService.establecerCobrosPendientes();
            this._cobrosService.estabalecerFacturasSeleccionadas([]);
            this._cobrosService.establecerCobrosPagar([]);
            this._cobrosService.establecerExisteErrorSeleccion(false);
          },
        });
    }
  }

  contieneAbonoFactura() {
    let abono = false;
    let factura = false;
    let cobros = this.cobrosPagar;

    cobros.forEach((cobro) => {
      if (cobro.importe > 0) factura = true;

      if (cobro.importe < 0) abono = true;
    });
    return abono && factura;
  }

  descargarPdfFacturasPendientes() {
    this.cargando = true;
    this._cobrosService.obtenerUrlPdfFacturasPendientes().subscribe({
      next: (data: any) => {
        if (data.existeError || !data.data)
          this._alerta.mostrarAlerta(
            this._mensaje.errorNoFirmaFactura,
            this._mensaje.alertError
          );
        else {
          window.open(data.data.url, '_blank');
        }
      },
      error: (error: any) => {
        if (error.error.errors)
          this._alerta.mostrarAlerta(
            Object.values(error.error.errors).join(' \n'),
            'error',
            ''
          );
        else this._alerta.mostrarAlerta(error.error.error, 'error', '');
        this.cargando = false;
      },
      complete: () => {
        this.cargando = false;
      },
    });
  }
}
