import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
//import * as moment from 'moment';
import { Alerta } from 'src/app/mensajes/helperAlerta';
import { Mensaje } from 'src/app/mensajes/mensajes';
import { BuzonModel } from 'src/app/models/buzon.model';
import { BuzonService } from 'src/app/services/buzon/buzon.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-buzon',
  templateUrl: './buzon.component.html',
  styleUrls: ['./buzon.component.css'],
  providers: [],
})
export class BuzonComponent implements OnInit {
  private _alerta: Alerta;
  private _mensaje: Mensaje;
  _buzon: Array<BuzonModel> = [];
  cargando: boolean = false;

  start = new FormControl(new Date());
  end = new FormControl(new Date());

  dateDesde = new FormControl(new Date());
  dateHasta = new FormControl(new Date());
  serializedDate = new FormControl(new Date().toLocaleDateString('en-CA'));

  fecha1: Date;

  constructor(
    private _buzonService: BuzonService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this._alerta = new Alerta();
    this.cargaGeneral().then((data: any) => {
      let buzon = data.map((item: any) => {
        let entrada = new BuzonModel();
        entrada.cobro = item['No_ cobro'];
        entrada.fechaCobro = item['Fecha cobro'];
        entrada.mensaje = item['Mensaje'];
        return entrada;
      });
      this._buzon = buzon;
    });

    this._buzonService.cargando.subscribe(
      (cargando) => (this.cargando = cargando)
    );
  }

  buscar() {
    const id = this.cookieService.get('id');
    /* const desde = moment(this.dateDesde.value).format('yyyy-MM-DD');
    const hasta = moment(this.dateHasta.value).format('yyyy-MM-DD'); */
    const desde = new Date(this.dateDesde.value)
      ?.toLocaleDateString('en-CA')
      .slice(0, 10);
    const hasta = new Date(this.dateHasta.value)
      ?.toLocaleDateString('en-CA')
      .slice(0, 10);
    this.cargando = true;
    this._buzonService
      .listarBuzon({ codUsuario: id, fecDesde: desde, fecHasta: hasta })
      .subscribe({
        next: (data: any) => {
          if (data.existeError) {
            this._alerta.mostrarAlerta(
              data.mensaje,
              this._mensaje.alertAdvertencia
            );
          } else {
            let buzon = data.data.map((item: any) => {
              let entrada = new BuzonModel();
              entrada.cobro = item['No_ cobro'];
              entrada.fechaCobro = item['Fecha cobro'];
              entrada.mensaje = item['Mensaje'];
              return entrada;
            });
            this._buzon = buzon;
          }
        },
        error: (error: any) => {
          if (error.error.errors)
            this._alerta.mostrarAlerta(
              Object.values(error.error.errors).join(' \n'),
              'error',
              ''
            );
          else this._alerta.mostrarAlerta(error.error.error, 'error', '');
          this.cargando = false;
        },
        complete: () => {
          this.cargando = false;
        },
      });
  }

  cargaGeneral() {
    return new Promise((resolve, reject) => {
      const id = this.cookieService.get('id');
      const desde = new Date(this.dateDesde.value)
        ?.toLocaleDateString('en-CA')
        .slice(0, 10);
      const hasta = new Date(this.dateHasta.value)
        ?.toLocaleDateString('en-CA')
        .slice(0, 10);
      this.cargando = true;
      this._buzonService
        .listarBuzon({ codUsuario: id, fecDesde: desde, fecHasta: hasta })
        .subscribe({
          next: (data: any) => {
            if (data.existeError) {
              reject(data);
              this._alerta.mostrarAlerta(
                data.mensaje,
                this._mensaje.alertAdvertencia
              );
            } else {
              resolve(data.data);
            }
          },
          error: (error: any) => {
            if (error.error.errors)
              this._alerta.mostrarAlerta(
                Object.values(error.error.errors).join(' \n'),
                'error',
                ''
              );
            else this._alerta.mostrarAlerta(error.error.error, 'error', '');
            this.cargando = false;
          },
          complete: () => {
            this.cargando = false;
          },
        });
    });
  }
}
