import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BuzonService } from 'src/app/services/buzon/buzon.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent {
  constructor(private router: Router, private _buzonService: BuzonService) {}
  irAEmails() {
    this.router.navigate(['buzon']);
  }
}
