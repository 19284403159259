import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {

  constructor(public router: Router, public loguinService: LoginService) { }


  canActivate() {
    if (this.loguinService.estaLogueado()) {
      return true;
    }
    else {
      this.router.navigate(['/login']);
      return false;
    }
  }

}
