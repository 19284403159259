import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Alerta } from 'src/app/mensajes/helperAlerta';
import { CobroService } from 'src/app/services/cobro/cobro.service';

@Component({
  selector: 'app-pago-redsys-ok',
  templateUrl: './pago-redsys-ok.component.html',
  styleUrls: ['./pago-redsys-ok.component.css'],
})
export class PagoRedsysOkComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  idPago: string = '';
  datosPago: any;
  cobros: Array<any>;
  cliente: string;
  importeTotal: number;
  private _alerta: Alerta;

  constructor(
    public router: Router,
    private cookieService: CookieService,
    private cService: CobroService
  ) {
    this._alerta = new Alerta();
  }

  ngOnInit(): void {
    this.idPago = this.cookieService.get('id_pago')
      ? this.cookieService.get('id_pago')
      : '0';
    this.setDatosPago();
  }

  volver() {
    this.router.navigate(['cobros']);
  }

  setImporteTotal() {
    this.importeTotal = this.cobros.reduce(
      (accumulator, cobro) => accumulator + cobro[1],
      0
    );
  }

  setDatosPago() {
    setTimeout(() => {
      this.cService.obtenerPagoRedsys(this.idPago).subscribe({
        next: (data: any) => {
          this.datosPago = data.data;
          this.cobros = JSON.parse(
            decodeURIComponent(this.datosPago.ds_merchant_data)
          ).cobros;
          this.cliente = JSON.parse(
            decodeURIComponent(this.datosPago.ds_merchant_data)
          ).cliente;
          this.setImporteTotal();
        },
        error: (err: any) => {
          this.datosPago = null;
          if (err.error.errors)
            this._alerta.mostrarAlerta(
              Object.values(err.error.errors).join(' \n'),
              'error',
              ''
            );
          else this._alerta.mostrarAlerta(err.error.error, 'error', '');
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
    }, 2000);
  }

  ngOnDestroy(): void {
    this.cookieService.delete('id_pago');
  }
}
