import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrums',
  templateUrl: './breadcrums.component.html',
  styles: [
  ],
})
export class BreadcrumsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
