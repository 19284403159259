<mat-toolbar
  class="header tws-drop-shadow-md tws-flex tws-justify-between tws-bg-white !tws-py-12 tws-z-50 tws-fixed"
>
  <div class="header__title tws-flex tws-items-center">
    <div class="tws-mx-4">
      <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo" />
    </div>
    <button class="header__title-button" mat-mini-fab (click)="openMenu()">
      <mat-icon *ngIf="!isMenuOpened" class="header__title-button-icon"
        >menu</mat-icon
      >
      <mat-icon *ngIf="isMenuOpened" class="header__title-button-icon"
        >arrow_back</mat-icon
      >
    </button>
  </div>

  <div class="header-toolbar tws-flex tws-justify-center tws-mr-7">
    <app-email class="tws-mx-4"></app-email>
    <app-user [user]="usuario | async" (signOut)="signOut()"></app-user>
  </div>
</mat-toolbar>
