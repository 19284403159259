import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.prod';
import { BehaviorSubject } from 'rxjs';
import { CobroModel } from 'src/app/models/cobro.model';

@Injectable({
  providedIn: 'root',
})
export class CobrosEfectuadosService {
  private url = '';
  private baseUrlApi: String;
  private _cargando = new BehaviorSubject<boolean>(false);
  private _error = new BehaviorSubject<string>('');

  private _cobros = new BehaviorSubject<CobroModel[]>([]);
  private _cobrosSeleccionados = new BehaviorSubject<CobroModel[]>([]);

  constructor(private http: HttpClient) {
    this.url = environment.baseUrl;
    this.baseUrlApi = environment.baseUrlApi;
  }

  get cargando() {
    return this._cargando.asObservable();
  }

  get error() {
    return this._error.asObservable();
  }

  get cobros() {
    return this._cobros.asObservable();
  }

  get cobrosSeleccionados() {
    return this._cobrosSeleccionados.asObservable();
  }

  establecerSeleccionados(cobros: Array<CobroModel>) {
    this._cobrosSeleccionados.next(cobros);
  }

  establecerCobros(
    fechaDesde: Date,
    fechaHasta: Date,
    importe: string,
    factura: string,
    tipoCobro: string
  ) {
    let tipo = '';
    if (tipoCobro == 'proceso') {
      tipo = 'proceso';
    } else if (tipoCobro == 'todos') {
      tipo = 'todo';
    } else {
      tipo = 'completado';
    }
    factura = factura ? factura : '0';
    importe = importe ? importe : '0';
    let fechaDesde1: String = new Date(fechaDesde)
      .toLocaleDateString('en-CA')
      .slice(0, 10);
    let fechaHasta1: String = new Date(fechaHasta)
      .toLocaleDateString('en-CA')
      .slice(0, 10);
    this._cargando.next(true);
    this.http
      .get(
        this.baseUrlApi +
          `cobros/${fechaDesde1}/${fechaHasta1}/${tipo}/0/0/0/0/${factura}/${importe}`
      )
      .subscribe({
        next: (data: any) => {
          let cobros = [];
          if (tipoCobro != 'todos') {
            cobros = data.data.map((el: any) => {
              let cobro = new CobroModel();
              cobro.representante = el.Representante;
              cobro.cliente = parseInt(el.cod_cliente);
              cobro.fechaEnvio = el.fecha_env;
              cobro.tipoCobro = parseInt(el.tipo_cobro);
              cobro.pedido = el.Factura;
              cobro.factura = el.cod_factura;
              cobro.nombreCliente = el.nom_cliente;
              cobro.fechaFactura = el.fec_factura;
              cobro.fechaVencimiento = el.fechavenc;
              cobro.fechaCobro = el['Fecha cobro'];
              cobro.importe = parseFloat(el.importe);
              cobro.importePagado = parseFloat(el['Importe pagado']);
              cobro.valorPagado = parseFloat(el.VALOR_PAGADO);
              cobro.codigoTipo = el.codigo_tipo;
              cobro.remesar = parseInt(el.Remesar);
              cobro.estado = tipoCobro;
              cobro.noEntrada = parseInt(el['No_ entrada']);
              cobro.aRemesar = el['A remesar'] == '0' ? false : true;
              return cobro;
            });
          } else {
            cobros = data.data.enProceso.map((el: any) => {
              let cobro = new CobroModel();
              cobro.representante = el.Representante;
              cobro.cliente = parseInt(el.cod_cliente);
              cobro.fechaEnvio = el.fecha_env;
              cobro.tipoCobro = parseInt(el.tipo_cobro);
              cobro.pedido = el.Factura;
              cobro.factura = el.cod_factura;
              cobro.nombreCliente = el.nom_cliente;
              cobro.fechaFactura = el.fec_factura;
              cobro.fechaVencimiento = el.fechavenc;
              cobro.fechaCobro = el['Fecha cobro'];
              cobro.importe = parseFloat(el.importe);
              cobro.importePagado = parseFloat(el['Importe pagado']);
              cobro.valorPagado = parseFloat(el.total_pagado);
              cobro.codigoTipo = el.codigo_tipo;
              cobro.remesar = parseInt(el.Remesar);
              cobro.estado = 'proceso';
              cobro.noEntrada = parseInt(el['No_ entrada']);
              cobro.aRemesar = el['A remesar'] == '0' ? false : true;
              return cobro;
            });
            let cobros2 = data.data.completados.map((el: any) => {
              let cobro = new CobroModel();
              cobro.representante = el.Representante;
              cobro.cliente = parseInt(el.cod_cliente);
              cobro.fechaEnvio = el.fecha_env;
              cobro.tipoCobro = parseInt(el.tipo_cobro);
              cobro.pedido = el.Factura;
              cobro.factura = el.cod_factura;
              cobro.nombreCliente = el.nom_cliente;
              cobro.fechaFactura = el.fec_factura;
              cobro.fechaVencimiento = el.fechavenc;
              cobro.fechaCobro = el['Fecha cobro'];
              cobro.importe = parseFloat(el.importe);
              cobro.importePagado = parseFloat(el['Importe pagado']);
              cobro.valorPagado = parseFloat(el.total_pagado);
              cobro.codigoTipo = el.codigo_tipo;
              cobro.remesar = parseInt(el.Remesar);
              cobro.estado = 'completados';
              cobro.noCobro = parseInt(el['No_ cobro']);
              cobro.noEntrada = parseInt(el['No_ entrada']);
              cobro.aRemesar = el['A remesar'] == '0' ? false : true;
              return cobro;
            });
            cobros = [...cobros, ...cobros2];
          }
          this._cobros.next(cobros);
          this._cobrosSeleccionados.next([]);
        },

        error: (error) => {
          if (error.error.errors)
            this._error.next(Object.values(error.error.errors).join(' \n'));
          else this._error.next(error.error.error);
          this._cargando.next(false);
        },
        complete: () => {
          this._cargando.next(false);
        },
      });
  }

  reversar(cobros: Array<CobroModel>) {
    return this.http.post(`${this.baseUrlApi}cobros/reversar`, {
      cobros,
    });
  }
}
