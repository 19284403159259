import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Mensaje } from 'src/app/mensajes/mensajes';
import { Alerta } from 'src/app/mensajes/helperAlerta';
import { MatDialog } from '@angular/material/dialog';
import { TarjetacobroComponent } from 'src/app/components/tarjetacobro/tarjetacobro.component';
import { FormControl, Validators } from '@angular/forms';
import _ from 'lodash';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { FacturaSeleccionadaModel } from 'src/app/models/facturaSelecionada.model';
import { CobrosEfectuadosService } from 'src/app/services/cobros-efectuados/cobros-efectuados.service';
import { CobroModel } from 'src/app/models/cobro.model';
import { ModalFirmaComponent } from 'src/app/components/modal-firma/modal-firma.component';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-cobrosefectuados',
  templateUrl: './cobros-efectuados.component.html',
})
export class CobrosEfectuadosComponent implements OnInit {
  private _alerta: Alerta;
  private _mensaje: Mensaje;
  panelOpenState: boolean = true;
  panelResumenState: boolean = false;
  cobros: Array<CobroModel>;
  cobrosSeleccionados: Array<CobroModel>;
  fechaDesde: FormControl;
  fechaHasta: FormControl;
  numFactura: FormControl;
  importe: FormControl;
  tiposCobros: FormControl;
  cargando: boolean = false;

  calcularInputControl: FormControl;

  @ViewChild(TarjetacobroComponent)
  componentProperty!: TarjetacobroComponent;

  constructor(
    private _cobrosEfectuadosService: CobrosEfectuadosService,
    public dialog: MatDialog,
    private scroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    this._mensaje = new Mensaje();
    this._alerta = new Alerta();
    let today = new Date();
    this.fechaDesde = new FormControl(new Date());
    this.fechaHasta = new FormControl(
      new Date(
        today.getTime() + ((5 - today.getDay() + 7) % 7) * 24 * 60 * 60 * 1000
      )
    );
    this.importe = new FormControl('');
    this.numFactura = new FormControl('');
    this.tiposCobros = new FormControl('todos');
    this._cobrosEfectuadosService.establecerCobros(
      this.fechaDesde.value,
      this.fechaHasta.value,
      this.importe.value,
      this.numFactura.value,
      this.tiposCobros.value
    );
    this._cobrosEfectuadosService.cobros.subscribe(
      (cobros) => (this.cobros = cobros)
    );

    this._cobrosEfectuadosService.cobrosSeleccionados.subscribe(
      (cobros) => (this.cobrosSeleccionados = cobros)
    );
    this._cobrosEfectuadosService.cargando.subscribe(
      (cargando) => (this.cargando = cargando)
    );

    this._cobrosEfectuadosService.error.subscribe((error) => {
      if (error) this._alerta.mostrarAlerta(error, 'error', '');
    });
  }

  irApagar() {
    this.scroller.scrollToAnchor('seccionPagar');
  }

  buscar() {
    this._cobrosEfectuadosService.establecerCobros(
      this.fechaDesde.value,
      this.fechaHasta.value,
      this.importe.value,
      this.numFactura.value,
      this.tiposCobros.value
    );
  }

  getTotalPagado() {
    let total = 0;
    this.cobros.map((cobro, index) => {
      if (cobro.estado == 'proceso') total += cobro.importePagado;
      else total += cobro.importePagado;
    });
    return this.formatMoney(total);
  }

  getImporteReversar() {
    let total = 0;
    this.cobrosSeleccionados.map((cobro, index) => {
      total += cobro.importePagado;
    });
    return this.formatMoney(total);
  }

  getTotalFacturas() {
    return this.cobros.reduce(
      (accumulator: number, cobro) => accumulator + 1,
      0
    );
  }

  formatMoney(val: any) {
    return UtilsService.formatMoney(val);
  }

  seleccionarFactura(factura: FacturaSeleccionadaModel) {
    let seleccionProvi: Array<CobroModel>;
    if (!factura.seleccionada) {
      seleccionProvi = this.cobrosSeleccionados.filter(
        (el) => el.factura != factura.factura
      );
      this._cobrosEfectuadosService.establecerSeleccionados(seleccionProvi);
    } else {
      let cobro = this.cobros.find(
        (cobro) =>
          cobro.noEntrada == factura.noEntrada &&
          cobro.factura == factura.factura
      );
      seleccionProvi = [...this.cobrosSeleccionados, cobro];
      this._cobrosEfectuadosService.establecerSeleccionados(seleccionProvi);
    }
  }

  verFirma(urlFirma: string) {
    if (urlFirma.includes('.pdf')) {
      window.open(UtilsService.replaceAfterTempWithEncoded(urlFirma), '_blank');
    } else {
      const dialogRef = this.dialog.open(ModalFirmaComponent, {
        width: '1000px',
        height: '1000px',
        data: { urlFirma: urlFirma },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  reversar() {
    let mensaje = '';
    this.cobrosSeleccionados.forEach((cobro: CobroModel) => {
      mensaje += cobro.factura + ' ';
    });
    mensaje = 'Se efectuara el reverso de la(s) factura(s): ' + mensaje;
    let titulo = 'Reversar cobros por valor de ' + this.getImporteReversar();
    this._alerta
      .confirmarAlerta(titulo, mensaje, this._mensaje.alertAdvertencia)
      .then((result: any) => {
        if (result.value) {
          this.cargando = true;
          this._cobrosEfectuadosService
            .reversar(this.cobrosSeleccionados)
            .subscribe({
              next: (data: any) => {
                if (data.existeError) {
                  this._alerta.mostrarAlerta(data.mensaje, 'error', '');
                } else {
                  this._alerta.mostrarAlerta(
                    `Cobro ${data.data} reversados correctamente`,
                    'success',
                    ''
                  );
                  this._cobrosEfectuadosService.establecerCobros(
                    this.fechaDesde.value,
                    this.fechaHasta.value,
                    this.importe.value,
                    this.numFactura.value,
                    this.tiposCobros.value
                  );
                }
              },
              error: (error: any) => {
                if (error.error.errors)
                  this._alerta.mostrarAlerta(
                    Object.values(error.error.errors).join(' \n'),
                    'error',
                    ''
                  );
                else this._alerta.mostrarAlerta(error.error.error, 'error', '');
                this._cobrosEfectuadosService.establecerSeleccionados([]);
                this._cobrosEfectuadosService.establecerCobros(
                  this.fechaDesde.value,
                  this.fechaHasta.value,

                  this.importe.value,
                  this.numFactura.value,
                  this.tiposCobros.value
                );
                this.cargando = false;
              },
              complete: () => {
                this.cargando = false;
              },
            });
        }
      });
  }
}
