<div class="tws-flex tws-flex-col tws-items-center">
  <button class="user-button" mat-mini-fab [matMenuTriggerFor]="userMenu">
    <mat-icon class="user-button__icon">person</mat-icon>
  </button>
  <span class="tws-mt-2 tws-text-xs">Usuario</span>
</div>
<mat-menu #userMenu="matMenu" xPosition="before">
  <div class="tws-p-4">
    <div class="u-img tws-flex">
      <div class="tws-w-5/12">
        <img src="assets/images/users/1.jpg" alt="user" />
      </div>
    </div>
    <div class="user-menu-title tws-py-4">
      <h4 class="user-menu-title__name">{{ user.nombre }}</h4>
    </div>
    <div class="sign-button-wrapper">
      <button class="sign-button" mat-flat-button (click)="signOutEmit()">
        <mat-icon class="logout">logout</mat-icon>
        Cerrar sesión
      </button>
    </div>
  </div>
</mat-menu>
