import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})


export class PusherService {
  private url = '';

  constructor(private http: HttpClient,
    private swPush: SwPush, public router: Router) {
    this.url = environment.baseUrl;
  }

  public subscribeToNotifications() {
    if (this.swPush.isEnabled) {
      this.swPush.requestSubscription({
        serverPublicKey: 'BFserGcotpRhRoCWj1A_fpU06kG86NlA5ymxP1WkItxOQYvX746e4zm4kBkL3I3dOZghp97PCgdrJxnt7stxdFQ'
      })
        .then(subscription => {
          this.saveSubscription(subscription).subscribe((data: any) => {
            console.log(data);
          });
          this.showNotification();
          this.watchNotifications();
        })
        .catch(e => {
          this.removeSubscription();
        });
    }
  }

  private showNotification() {
    this.swPush.messages.subscribe(notification => {
      const notificationData: any = notification;
      navigator.serviceWorker.getRegistration().then(reg => {
        reg.showNotification(notificationData.title, notificationData)/* .then(res => {
          console.log(res);
        }, err => {
          console.error(err);
        }); */
      });
    });
  }

  private watchNotifications() {
    this.swPush.notificationClicks.subscribe(({ action, notification }) => {
      if (action === 'ver_mensajes')
        window.open('/#/buzon', '_blank')
      //this.router.navigate(['/buzon']);
    });
  }

  private removeSubscription() {
    return this.http.post(`${this.url}guardar-suscripcion-clientes`, {});
  }

  private saveSubscription(subscription) {
    return this.http.post(`${this.url}guardar-suscripcion-clientes`,
      JSON.parse(JSON.stringify(subscription))
    );
  }
}
