import Swal from 'sweetalert2'
import _ from 'lodash';
export class Alerta {
  resp = false;


  mostrarAlerta(mensaje: string, tipo: string = null, text: string = null) {
    Swal.fire({
      title: mensaje,
      text,
      icon: tipo == null ? 'success' : tipo === 'e' ? 'error' : 'warning',
      showClass: {
        popup: 'animated fadeInDown faster'
      },
      hideClass: {
        popup: 'animated fadeOutUp faster'
      },
      customClass: {
        confirmButton: '!tws-bg-blue-400'
      }
    })
  }



  confirmarAlerta(titulo: string, mensaje: string, tipo: string = null) {

    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: tipo == null ? 'success' : tipo === 'e' ? 'error' : 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar!',
      cancelButtonText: 'Cancelar'
    });
  }

}
