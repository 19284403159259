export class FacturaPendienteModel {
  codCliente: string;
  importe: number;
  importePagado: number;
  importeProcesando: number;
  representante: string;
  factura: string;
  pedido: string;
  nombreCliente: string;
  tipo: string;
  fechaFactura: string;
  fechaRegistro: string;
  fechaVencimiento: string;
  nombreRepartidor: string;
  fechaEnvio: string;
  urlpdf: string;
  procesando: boolean;
  noEntrada: number;
  aRemesar: boolean;
  conError: boolean;
}
