import {
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PagesComponent } from './pages.component';

import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../shared/shared.module';
import { PAGES_ROUTES } from './pages.routes';
import { CobrosComponent } from './cobros/cobros.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BuzonComponent } from './buzon/buzon.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import {
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TarjetacobroComponent } from '../components/tarjetacobro/tarjetacobro.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { CobrosEfectuadosComponent } from './cobros-efectuados/cobros-efectuados.component';
import { MatRadioModule } from '@angular/material/radio';
import { TarjetaEfectuadosComponent } from '../components/tarjeta-efectuados/tarjeta-efectuados.component';
import { PagoRedsysOkComponent } from './pago-redsys-ok/pago-redsys-ok.component';
import { PagoRedsysKoComponent } from './pago-redsys-ko/pago-redsys-ko.component';
import { MatListModule } from '@angular/material/list';
import { PedidosComponent } from './pedidos/pedidos.component';
import { DatosPara347Component } from './datos-para347/datos-para347.component';

@NgModule({
  declarations: [
    PagesComponent,
    CobrosComponent,
    BuzonComponent,
    TarjetacobroComponent,
    CobrosEfectuadosComponent,
    TarjetaEfectuadosComponent,
    PagoRedsysOkComponent,
    PagoRedsysKoComponent,
    PedidosComponent,
    DatosPara347Component,
  ],
  providers: [
    TarjetacobroComponent,
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es-ES',
    },
  ],
  exports: [],
  imports: [
    PAGES_ROUTES,
    SharedModule,
    FormsModule,
    CommonModule,
    BrowserModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    MatListModule,
    MatProgressSpinnerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule {}
