//PRUEBS
// export const environment = {
//   production: false,
//   baseUrl: 'http://www.surcanal.eu:85/api/v1/general/cobros-banco/',
//   lenghPassWhatapp: 6
// };

//PD
export const environment = {
  production: false,
  baseUrl: 'https://api.surcanal.eu/api/v2/',
  baseUrlApi: 'https://api.surcanal.eu/api/v2/',
  lenghPassWhatapp: 6,
};
