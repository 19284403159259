import { FacturaModel } from './factura.model';

export class UsuarioModel {
  pass: string;
  repitePass: string;
  dni: string;
  usuario: string;
  codigoWhatsapp: string;
  id: string;
  movil: string;
  ciudad: string;
  direccion: string;
  nombre: string;
  vendedor: string;
  facturas: Array<FacturaModel>;
  facturasPorPagar: Array<FacturaModel>;
  facturasPorReversar: Array<FacturaModel>;
  tipoCobro: number;
  tarjeta: number;
  banco: number
}
