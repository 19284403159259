<app-layout>
  <div class="tws-max-w-full">
    <div class="tws-w-full">
      <div class="card tws-py-8 tws-min-h-screen">
        <div class="card-body" id="seccionPagar">
          <h4 class="card-title tws-text-lg">Lista de Cobros</h4>
          <h6 class="card-subtitle tws-text-md">
            Pantalla que se visualiza los cobros ya efectuados
          </h6>
          <div class="tws-mt-5">
            <mat-expansion-panel
              [expanded]="panelOpenState === true"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h4 class="card-title">Lista de Cobros</h4>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div
                class="tws-grid tws-grid-cols-1 md:tws-grid-cols-2 lg:tws-grid-cols-5 tws-gap-3"
              >
                <div>
                  <mat-form-field
                    appearance="fill"
                    class="tws-max-w-full tws-w-full"
                  >
                    <mat-label>Factura</mat-label>
                    <input matInput [formControl]="numFactura" />
                    <mat-error *ngIf="numFactura.hasError('required')">
                      Valor requerido
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="">
                  <mat-form-field
                    appearance="fill"
                    class="tws-max-w-full tws-w-full"
                  >
                    <mat-label>Importe</mat-label>
                    <input matInput [formControl]="importe" />
                    <mat-error *ngIf="importe.hasError('required')">
                      Valor requerido
                    </mat-error>
                    <mat-error *ngIf="importe.hasError('min')">
                      Valor tiene que ser mayor de 0
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="">
                  <mat-form-field
                    appearance="fill"
                    class="tws-max-w-full tws-w-full"
                  >
                    <mat-label>Fecha Desde</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker"
                      [formControl]="fechaDesde"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="">
                  <mat-form-field
                    appearance="fill"
                    class="tws-max-w-full tws-w-full"
                  >
                    <mat-label>Fecha Hasta</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker1"
                      [formControl]="fechaHasta"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker1"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="tws-flex tws-flex-wrap">
                <div class="tws-my-2">
                  <mat-radio-group [formControl]="tiposCobros">
                    <mat-radio-button class="" color="primary" value="todos"
                      >Todos</mat-radio-button
                    >
                    <mat-radio-button class="" color="primary" value="proceso"
                      >Proceso</mat-radio-button
                    >
                    <mat-radio-button
                      class=""
                      color="primary"
                      value="completados"
                      >Completados</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </div>
              <div class="tws-flex tws-flex-wrap tws-my-2">
                <div
                  class="tws-w-full md:tws-hidden tws-mb-2 tws-bg-gray-200 tws-p-4"
                >
                  <mat-card class="tws-w-full">
                    <mat-card-content>
                      <div class="tws-grid tws-grid-cols-2 tws-gap-2">
                        <div class="tws-flex tws-font-bold tws-my-sm">
                          <div class="tws-mr-1">Numero Fac:</div>
                          <div>
                            {{ getTotalFacturas() }}
                          </div>
                        </div>
                        <div class="tws-flex tws-font-bold tws-my-sm">
                          <div class="tws-mr-1">Total Pagado:</div>
                          <div>
                            {{ getTotalPagado() }}
                          </div>
                        </div>
                        <div
                          *ngIf="cobrosSeleccionados.length > 0"
                          class="tws-flex tws-font-bold tws-my-sm"
                        >
                          <div class="tws-mr-1">Importe Reversar:</div>
                          <div>
                            {{ getImporteReversar() }}
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <div class="tws-flex tws-justify-between">
                <div
                  class="tws-mt-4 tws-mb-2 lg:tws-w-4/12 md:tws-w-4/12 tws-w-11/12"
                >
                  <button
                    mat-raised-button
                    color="accent"
                    (click)="buscar()"
                    class="!tws-py-6 tws-mr-4"
                  >
                    Buscar
                  </button>
                  <button
                    *ngIf="cobrosSeleccionados.length > 0"
                    mat-raised-button
                    color="accent"
                    (click)="reversar()"
                    class="!tws-py-6"
                  >
                    Reversar
                  </button>
                </div>
                <div
                  class="lg:tws-w-5/12 md:tws-w-5/12 tws-w-11/12 tws-hidden md:tws-block tws-mr-10 tws-bg-gray-200 tws-p-4"
                >
                  <mat-card class="tws-w-full">
                    <mat-card-content>
                      <div class="tws-grid tws-grid-cols-2 tws-gap-2">
                        <div class="tws-flex tws-font-bold tws-my-sm">
                          <div class="tws-mr-1">Numero Fac:</div>
                          <div>
                            {{ getTotalFacturas() }}
                          </div>
                        </div>
                        <div class="tws-flex tws-font-bold tws-my-sm">
                          <div class="tws-mr-1">Total Pagado:</div>
                          <div>
                            {{ getTotalPagado() }}
                          </div>
                        </div>
                        <div
                          *ngIf="cobrosSeleccionados.length > 0"
                          class="tws-flex tws-font-bold tws-my-sm"
                        >
                          <div class="tws-mr-1">Importe Reversar:</div>
                          <div>
                            {{ getImporteReversar() }}
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
          <div *ngIf="cargando" class="tws-flex tws-justify-center tws-mt-12">
            <mat-spinner
              strokeWidth="4"
              class="!tws-text-blue-400"
            ></mat-spinner>
          </div>
          <div
            *ngIf="cobros.length === 0 && cargando == false"
            class="tws-flex tws-justify-center tws-mt-12"
          >
            <div>No se ha encontrado resultados</div>
          </div>
          <div
            *ngIf="cobros.length !== 0 && cargando == false"
            class="tws-grid tws-mt-12 tws-grid-cols-1 lg:tws-grid-cols-4 xxl:tws-grid-cols-5 md:tws-grid-cols-2 tws-gap-4"
          >
            <div *ngFor="let cobro of cobros">
              <app-tarjetaefectuados
                [id]="cobro"
                (seleccion)="seleccionarFactura($event)"
                (urlFirma)="verFirma($event)"
                [cobro]="cobro"
              ></app-tarjetaefectuados>
            </div>
          </div>
        </div>
      </div>
      <div class="tws-fixed tws-bottom-6 tws-right-10">
        <button
          (click)="irApagar()"
          class="tws-cursor-pointer"
          mat-fab
          color="accent"
          aria-label="arrow up"
        >
          <mat-icon class="arrow_upward">arrow_upward</mat-icon>
        </button>
      </div>
    </div>
  </div>
</app-layout>
