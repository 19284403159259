import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  static replaceAfterTempWithEncoded = (url: string) => {
    const tempIndex = url.indexOf('/temp/') + 6; // add 6 to exclude '/temp/' itself

    const beforeTemp = url.substring(0, tempIndex);

    const afterTemp = url.substring(tempIndex);
    const endIndex = afterTemp.indexOf('?'); // find the index of the next question mark character
    const pdfString = afterTemp.substring(0, endIndex); // extract the string up to the question mark

    const encodedPdfString = btoa(pdfString); // encode the string in base64

    const newAfterTemp = encodedPdfString + afterTemp.substring(endIndex); // replace the pdfString with the encoded version

    return beforeTemp + newAfterTemp;
  };

  static formatMoney(value: number): String {
    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
    }).format(value);
  }

  static formatDate(date: string) {
    return new Date(date.replace(' ', 'T')).toLocaleDateString('es-ES');
  }

  /**
   * La función groupBy toma dos argumentos: el primer argumento es el
   * array que queremos agrupar y el segundo argumento es el nombre de
   * la propiedad por la que queremos agrupar los objetos.
   *
   * @param {array} arr
   * @param {string} property
   * @returns
   */
  static groupBy = (arr: Array<any>, property: any): any => {
    return arr.reduce(function (acc, obj: Array<any>) {
      let key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  /**
   * Compara dos números flotantes devuelve true si a es mayor que b.
   * @param {number} a - El primer número a comparar.
   * @param {number} b - El segundo número a comparar.
   * @returns {boolean} true si a es mayor que b, false en caso contrario.
   */
  static compareFloats(a: number, b: number) {
    let igual = Math.round(a * 100000) == Math.round(b * 100000);
    if (igual) return 1;
    else return Math.round(a * 100000) > Math.round(b * 100000);
  }
}
