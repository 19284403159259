import { Component, OnInit } from '@angular/core';
import { UsuarioModel } from '../../models/usuario.model';
import { Mensaje } from '../../mensajes/mensajes';
import { Alerta } from '../../mensajes/helperAlerta';
import { LoginService } from '../../services/login.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { environment } from '../../../environments/environment.prod';
import { generate } from 'generate-password-browser';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./login.component.css'],
})
export class RegisterComponent implements OnInit {
  usuario: UsuarioModel;
  mensaje: Mensaje;
  generadorPass: string;
  alerta: Alerta;
  visualizaCamposPass: boolean = false;
  visualizaErrorCodWhatsapp: boolean = false;
  bloquearBotonRegistrar: boolean = false;

  dni: string = '';
  isLinear = true;
  siguienteWhatapp: boolean = false;
  llaveWhatsapp: string = '';
  constructor(
    private auth: LoginService,
    public router: Router,
    private _formBuilder: FormBuilder,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.mensaje = new Mensaje();
    this.alerta = new Alerta();

    this.llaveWhatsapp = generate({
      length: environment.lenghPassWhatapp,
      numbers: true,
    });

    sessionStorage.setItem('llaveWhatsapp', this.llaveWhatsapp);
    this.limpiarFormulario();
  }

  limpiarFormulario() {
    this.usuario = new UsuarioModel();
    this.usuario.dni = '';
    this.usuario.codigoWhatsapp = '';
    this.usuario.pass = '';
    this.usuario.repitePass = '';
    this.visualizaCamposPass = false;
    this.visualizaErrorCodWhatsapp = false;
    this.bloquearBotonRegistrar = false;
  }

  validaDniExistente(stepper: MatStepper) {
    try {
      this.bloquearBotonRegistrar = true;
      if (this.usuario.dni === '') throw this.mensaje.mensajeDni;
      if (this.usuario.id === '') throw this.mensaje.mensajeId;

      this.formarCodigoUsuario(this.usuario);

      this.auth
        .validaDni(
          this.usuario.dni,
          this.cookieService.get('llaveWhatsapp'),
          this.usuario.id,
          this.usuario.usuario
        )
        .subscribe((data: any) => {
          if (data.existeError) {
            this.alerta.mostrarAlerta(
              data.mensaje,
              this.mensaje.alertAdvertencia
            );
            this.bloquearBotonRegistrar = false;
          } else {
            this.alerta.mostrarAlerta(this.mensaje.confirmacionCodigoSeguridad);
            this.usuario.id = data.data[0].id;
            this.usuario.movil = data.data[0].Movil;
            this.bloquearBotonRegistrar = false;

            stepper.next();
          }
        });
    } catch (error) {
      this.alerta.mostrarAlerta(error, this.mensaje.alertError);
      this.bloquearBotonRegistrar = false;
    }
  }

  formarCodigoUsuario(user: UsuarioModel) {
    try {
      let cantCaracteres = user.id.length;
      let ceros = '';
      for (let i = 0; i < 5 - cantCaracteres; i++) ceros += '0';

      user.usuario = 'C' + ceros + '' + user.id;
    } catch (error) {
      this.alerta.mostrarAlerta(error, this.mensaje.alertError);
      this.bloquearBotonRegistrar = false;
    }
  }

  registrar() {
    try {
      this.bloquearBotonRegistrar = true;
      if (!this.validaFormulario()) throw this.mensaje.formularioInvalido;
      if (!this.validaPassIdentica(this.usuario.pass, this.usuario.repitePass))
        throw this.mensaje.claveDiferente;
      this.auth.registrarUsuario(this.usuario).subscribe(
        (data: any) => {
          if (data.existeError) throw data.mensaje;
          else this.alerta.mostrarAlerta(this.mensaje.registroExitoso);

          this.bloquearBotonRegistrar = false;
          this.limpiarFormulario();
          this.router.navigate(['/login']);
        },
        (err) => {
          this.alerta.mostrarAlerta(err, this.mensaje.alertError);
          this.bloquearBotonRegistrar = false;
        }
      );
    } catch (error) {
      this.alerta.mostrarAlerta(error, this.mensaje.alertError);
      this.bloquearBotonRegistrar = false;
    }
  }

  validaPassIdentica(pass: string, RepitaPass: string) {
    return pass === RepitaPass ? true : false;
  }

  validacionCodigoWhatsapp(stepper: MatStepper) {
    try {
      this.bloquearBotonRegistrar = true;
      if (
        this.cookieService.get('llaveWhatsapp') === this.usuario.codigoWhatsapp
      ) {
        stepper.next();
        this.bloquearBotonRegistrar = false;
      } else throw this.mensaje.codigoWhatsappInvalido;
    } catch (error) {
      this.alerta.mostrarAlerta(error, this.mensaje.alertError);
      this.bloquearBotonRegistrar = false;
    }
  }

  validaFormulario() {
    if (this.usuario.dni === null) return false;
    if (this.usuario.codigoWhatsapp === null) return false;
    if (this.usuario.pass === null) return false;
    if (this.usuario.repitePass === null) return false;
    return true;
  }
}
