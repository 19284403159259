<mat-dialog-content>
  <mat-card style="max-width: 80vw">
    <mat-card-header class="row justify-content-between">
      <mat-card-title class="mx-2">Pagar</mat-card-title>
      <span class="spacer"></span>
      <button class="mx-2" mat-icon-button mat-dialog-close>
        <mat-icon aria-label="Cerrar diálogo">close</mat-icon>
      </button>
    </mat-card-header>

    <mat-card-content>
      <mat-horizontal-stepper [linear]="true" #stepper>
        <mat-step [stepControl]="firstStepForm">
          <form [formGroup]="firstStepForm">
            <ng-template matStepLabel>Metodo de pago</ng-template>
            <div
              class="row justify-content-around items-center mat-gutter-x-sm mat-gutter-y-sm"
            >
              <div
                style="height: 148px"
                class="mb-2 col-sm-12 col-md-5 col-lg-3"
              >
                <div
                  style="height: 100%"
                  [ngClass]="{
                    'is-selected text-white bg-info':
                      firstStepForm.get('metodoPago').value == 2,
                    'mat-color(text) pointer mat-elevation-z1 mat-rounded p-2 cursor-pointer': true
                  }"
                  (click)="selectMethod(2)"
                >
                  <img
                    width="116px"
                    loading="lazy"
                    src="assets/images/bizum.jpeg"
                    [ngClass]="{
                      'is-selected': firstStepForm.get('metodoPago').value == 2,
                      rounded: true,
                      'mx-auto d-block': true
                    }"
                  />
                  <div class="text-center text-h6">Bizum</div>
                </div>
              </div>
              <div
                style="height: 148px"
                class="mb-2 col-sm-12 col-md-5 col-lg-3"
              >
                <div
                  *ngIf="cobrosRemesando.length > 0"
                  class="tws-flex tws-h-full tws-justify-center tws-items-center tws-text-center tws-font-semibold"
                >
                  <div class="tws-text-center">
                    <span
                      *ngFor="let cobro of cobrosRemesando"
                      class="tws-text-center"
                    >
                      {{ cobro.factura + " " }}
                    </span>
                    remesando
                  </div>
                </div>
                <div
                  *ngIf="cobrosRemesando.length == 0"
                  style="height: 100%"
                  [ngClass]="{
                    'is-selected text-white bg-info':
                      firstStepForm.get('metodoPago').value == 0,
                    'mat-color(text) pointer mat-elevation-z1 mat-rounded p-2 cursor-pointer': true
                  }"
                  (click)="selectMethod(0)"
                >
                  <img
                    width="110px"
                    loading="lazy"
                    src="assets/images/remesa.png"
                    [ngClass]="{
                      'is-selected': firstStepForm.get('metodoPago').value == 0,
                      'mx-auto d-block': true
                    }"
                  />
                  <div class="text-center text-h6">Remesa</div>
                </div>
              </div>
              <div style="height: 148px" class="col-sm-12 col-md-5 col-lg-3">
                <div
                  style="height: 100%"
                  [ngClass]="{
                    'is-selected text-white bg-info':
                      firstStepForm.get('metodoPago').value == 4,
                    'mat-color(text) pointer mat-elevation-z1 mat-rounded p-2 cursor-pointer': true
                  }"
                  (click)="selectMethod(4)"
                >
                  <img
                    width="116px"
                    loading="lazy"
                    src="assets/images/transferencia.png"
                    [ngClass]="{
                      'is-selected': firstStepForm.get('metodoPago').value == 4,
                      'mx-auto d-block': true
                    }"
                  />
                  <div class="text-center text-h6">Transferencia</div>
                </div>
              </div>

              <div style="height: 148px" class="col-sm-12 col-md-5 col-lg-3">
                <div
                  style="height: 100%"
                  [ngClass]="{
                    'is-selected text-white bg-info':
                      firstStepForm.get('metodoPago').value == 7,
                    'mat-color(text) pointer mat-elevation-z1 mat-rounded p-2 cursor-pointer': true
                  }"
                  (click)="selectMethod(7)"
                >
                  <img
                    width="116px"
                    loading="lazy"
                    src="assets/images/pasarela.png"
                    [ngClass]="{
                      'is-selected': firstStepForm.get('metodoPago').value == 7,
                      'mx-auto d-block': true
                    }"
                  />
                  <div class="text-center text-h6">Pasarela</div>
                </div>
              </div>
            </div>
            <ng-template matStepperIcon="edit">
              <mat-icon>payment</mat-icon>
            </ng-template>

            <div class="button-container mt-3">
              <app-procesar-cobro-pasarela
                [cobros]="data.cobros"
                *ngIf="firstStepForm.get('metodoPago').value == 7"
              ></app-procesar-cobro-pasarela>
              <button
                *ngIf="firstStepForm.get('metodoPago').value != 7"
                mat-raised-button
                color="accent"
                matStepperNext
                (click)="pagar()"
              >
                Pagar
              </button>
            </div>
          </form>
        </mat-step>
      </mat-horizontal-stepper>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
