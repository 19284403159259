<form
  [formGroup]="formRedsys"
  (ngSubmit)="onSubmit($event)"
  [action]="formAction"
  method="post"
>
  <input
    type="hidden"
    name="Ds_MerchantParameters"
    formControlName="merchantParameters"
  />
  <input
    type="hidden"
    name="Ds_Signature"
    id="signature"
    formControlName="signature"
  />
  <input
    type="hidden"
    name="Ds_SignatureVersion"
    formControlName="signatureVersion"
  />
  <button
    type="submit"
    mat-raised-button
    color="accent"
    [disabled]="formRedsys.invalid"
  >
    Continuar
  </button>
</form>
