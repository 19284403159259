import { Injectable } from '@angular/core';
import { UsuarioModel } from '../models/usuario.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private url = '';
  private baseUrlApi: String;
  private _cargando = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.url = environment.baseUrl;
    this.baseUrlApi = environment.baseUrlApi;
  }

  get cargando() {
    return this._cargando.asObservable();
  }

  login(usuario: UsuarioModel) {
    return this.http.post(`${this.baseUrlApi}login/cliente`, {
      usuario: usuario.usuario,
      clave: usuario.pass,
    });
  }

  registrarUsuario(usuario: UsuarioModel) {
    return this.http.post(`${this.url}registro`, usuario);
  }

  validaDni(dni: string, codigoWhatsapp: string, id: string, usuario: string) {
    return this.http.post(`${this.url}validaExistencia`, {
      dni,
      codigoWhatsapp,
      id,
      usuario,
    });
  }

  validaUsuario(dni: string, id: string, usuario: string) {
    return this.http.post(`${this.url}validaUsuario`, { dni, id, usuario });
  }

  estaLogueado() {
    if (this.cookieService.get('id')) {
      return true;
    } else {
      return false;
    }
  }

  Logout() {
    this.cookieService.deleteAll();
    this.router.navigate(['/login']);
  }

  datosUsuario() {
    let _usuario = new UsuarioModel();
    _usuario.id = this.cookieService.get('id');
    _usuario.dni = this.cookieService.get('dni');
    _usuario.nombre = this.cookieService.get('nombre');
    _usuario.ciudad = this.cookieService.get('ciudad');
    _usuario.direccion = this.cookieService.get('direccion');
    _usuario.vendedor = this.cookieService.get('vendedor');

    return _usuario;
  }
}
