import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'surcanal';

  constructor() {


  }
  ngOnInit(): void {

    /* this.pusherService.listen('avisos-clientes', ".AvisoClienteEvent").subscribe((data) => {
      this.pusherService.notifyUser(data);
    }); */

  }
}
