<app-layout>
  <div class="tws-mt-6 tws-max-w-full">
    <div class="tws-w-full tws-relative">
      <div class="card tws-py-8">
        <div class="card-body tws-min-h-screen" id="seccionPagar">
          <h4 class="card-title tws-text-lg">Lista de Facturas</h4>
          <h6 class="card-subtitle tws-text-md">
            Pantalla que selecciona los importes a remesar
          </h6>
          <div class="tws-flex tws-justify-end tws-mt-5">
            <button
              mat-raised-button
              color="accent"
              (click)="cobrosEfectuados()"
              class="!tws-py-6"
            >
              Cobros Efectuados
            </button>
          </div>
          <div class="tws-mt-5">
            <mat-expansion-panel
              [expanded]="panelOpenState === true"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h4 class="card-title">Lista de Facturas</h4>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="tws-my-5 md:tws-hidden">
                <mat-expansion-panel
                  [expanded]="panelCalcularState === true"
                  (opened)="panelCalcularState = true"
                  (closed)="panelCalcularState = false"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title> </mat-panel-title>
                    <mat-panel-description>Calcular </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="tws-flex tws-flex-wrap">
                    <div class="tws-w-full">
                      <mat-form-field class="tws-text-sm tws-w-full tws-h-16">
                        <input
                          type="number"
                          matInput
                          [formControl]="calcularInputControl"
                        />
                        <mat-error
                          *ngIf="calcularInputControl.hasError('required')"
                        >
                          Valor requerido
                        </mat-error>
                        <mat-error *ngIf="calcularInputControl.hasError('min')">
                          Valor tiene que ser mayor de 0
                        </mat-error>
                        <mat-icon matSuffix class="tws-text-sm">euro</mat-icon>
                      </mat-form-field>
                    </div>
                    <div
                      class="md:tws-ml-6 lg:tws-w-4/12 md:tws-w-4/12 tws-w-11/12"
                    >
                      <button
                        class="!tws-py-6"
                        mat-raised-button
                        color="accent"
                        (click)="calcular()"
                      >
                        Calcular
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
              </div>

              <div class="tws-flex tws-flex-wrap">
                <div
                  class="tws-w-full md:tws-hidden tws-mb-2 tws-bg-gray-200 tws-p-4"
                >
                  <mat-card class="tws-w-full">
                    <mat-card-content>
                      <div class="tws-grid tws-grid-cols-2 tws-gap-2">
                        <div class="tws-flex tws-font-bold tws-my-sm">
                          <div class="tws-mr-1">Num. Fac:</div>
                          <div>
                            {{ getTotalFacturas() }}
                          </div>
                        </div>
                        <div class="tws-flex tws-font-bold tws-my-sm">
                          <div class="tws-mr-1">Num Fac. Pagar:</div>
                          <div>
                            {{ getTotalFacturasPagar() }}
                          </div>
                        </div>
                        <div class="tws-flex tws-font-bold tws-my-sm">
                          <div class="tws-mr-1">Imp. para cobrar:</div>
                          <div>
                            {{ formatMoney(getTotalImporte()) }}
                          </div>
                        </div>
                        <div class="tws-flex tws-font-bold">
                          <div class="tws-mr-1">Imp. cobrado:</div>
                          <div>
                            {{ formatMoney(getImporteCobrado()) }}
                          </div>
                        </div>
                        <div class="tws-flex tws-font-bold">
                          <div class="tws-mr-1">Cant. Pend:</div>
                          <div>
                            {{
                              formatMoney(
                                getTotalImporte() - getImporteCobrado()
                              )
                            }}
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div
                  class="lg:tws-w-4/12 md:tws-w-4/12 tws-w-11/12 tws-hidden md:tws-block"
                >
                  <mat-form-field class="tws-text-sm tws-w-full tws-h-16">
                    <input
                      type="number"
                      matInput
                      [formControl]="calcularInputControl"
                    />
                    <mat-error
                      *ngIf="calcularInputControl.hasError('required')"
                    >
                      Valor requerido
                    </mat-error>
                    <mat-error *ngIf="calcularInputControl.hasError('min')">
                      Valor tiene que ser mayor de 0
                    </mat-error>
                    <mat-icon matSuffix class="tws-text-sm">euro</mat-icon>
                  </mat-form-field>
                </div>
                <div
                  class="tws-hidden md:tws-block md:tws-ml-6 lg:tws-w-4/12 md:tws-w-4/12 tws-w-11/12"
                >
                  <button
                    class="!tws-py-6"
                    mat-raised-button
                    color="accent"
                    (click)="calcular()"
                  >
                    Calcular
                  </button>
                </div>
              </div>
              <div class="tws-flex tws-justify-between">
                <div
                  class="tws-mt-4 tws-mb-2 lg:tws-w-4/12 md:tws-w-4/12 tws-w-11/12"
                >
                  <button
                    class="!tws-py-6"
                    mat-raised-button
                    color="accent"
                    (click)="pagar()"
                  >
                    Pagar
                  </button>
                </div>
                <div
                  class="tws-mt-4 tws-mb-2 lg:tws-w-4/12 md:tws-w-4/12 tws-w-11/12"
                >
                  <button
                    class="!tws-py-6"
                    mat-raised-button
                    color="accent"
                    (click)="descargarPdfFacturasPendientes()"
                  >
                    LISTADO DE FACTURAS PENDIENTES EN PDF
                  </button>
                </div>
                <div
                  class="lg:tws-w-5/12 md:tws-w-5/12 tws-w-11/12 tws-hidden md:tws-block tws-mr-10 tws-bg-gray-200 tws-p-4"
                >
                  <mat-card class="tws-w-full">
                    <mat-card-content>
                      <div class="tws-grid tws-grid-cols-2 tws-gap-2">
                        <div class="tws-flex tws-font-bold tws-my-sm">
                          <div class="tws-mr-1">Num. Fac:</div>
                          <div>
                            {{ getTotalFacturas() }}
                          </div>
                        </div>
                        <div class="tws-flex tws-font-bold tws-my-sm">
                          <div class="tws-mr-1">Num Fac. Pagar:</div>
                          <div>
                            {{ getTotalFacturasPagar() }}
                          </div>
                        </div>
                        <div class="tws-flex tws-font-bold tws-my-sm">
                          <div class="tws-mr-1">Imp. para cobrar:</div>
                          <div>
                            {{ formatMoney(getTotalImporte()) }}
                          </div>
                        </div>
                        <div class="tws-flex tws-font-bold">
                          <div class="tws-mr-1">Imp. cobrado:</div>
                          <div>
                            {{ formatMoney(getImporteCobrado()) }}
                          </div>
                        </div>
                        <div class="tws-flex tws-font-bold">
                          <div class="tws-mr-1">Cant. Pend:</div>
                          <div>
                            {{
                              formatMoney(
                                getTotalImporte() - getImporteCobrado()
                              )
                            }}
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </mat-expansion-panel>
          </div>

          <div *ngIf="cargando" class="tws-flex tws-justify-center tws-mt-12">
            <mat-spinner
              strokeWidth="4"
              class="!tws-text-blue-400"
            ></mat-spinner>
          </div>
          <div
            *ngIf="cobrosPendientes.length === 0 && cargando == false"
            class="tws-flex tws-justify-center tws-mt-12"
          >
            <div>No se ha encontrado resultados</div>
          </div>

          <div
            *ngIf="cobrosPendientes.length !== 0 && cargando == false"
            class="tws-grid tws-mt-12 tws-grid-cols-1 lg:tws-grid-cols-4 xxl:tws-grid-cols-5 md:tws-grid-cols-2 tws-gap-4"
          >
            <div class="tws-w-full" *ngFor="let cobro of cobrosPendientes">
              <app-tarjetacobro
                [id]="cobro"
                (seleccion)="seleccionarFactura($event)"
                (urlFirma)="verFirma($event)"
                [cobro]="cobro"
                [cobrosPagar]="cobrosPagar"
                [existeErrorSeleccion]="existeErrorSeleccion"
              >
              </app-tarjetacobro>
            </div>
          </div>
        </div>
      </div>
      <div class="tws-fixed tws-bottom-6 tws-right-10">
        <button
          (click)="irApagar()"
          class="tws-cursor-pointer"
          mat-fab
          color="accent"
          aria-label="arrow up"
        >
          <mat-icon class="arrow_upward">arrow_upward</mat-icon>
        </button>
      </div>
    </div>
  </div>
</app-layout>
