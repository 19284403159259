<section
  id="wrapper"
  class="login-register tws-min-h-screen login-sidebar tws-flex tws-justify-center tws-items-center"
  style="
    background-image: url(assets/images/background/login-register.jpg);
    background-position: bottom;
  "
>
  <div class="card tws-p-6 tws-w-11/12 md:tws-w-5/12 lg:tws-w-5/12">
    <div class="card-body">
      <div class="tws-flex tws-flex-col tws-items-center">
        <div>
          <img src="assets/images/logo-icon.png" alt="Home" />
        </div>
        <div class="tws-text-xl tws-font-extrabold tws-my-5">SURCANAL</div>
      </div>

      <form
        ngNativeValidate
        [formGroup]="forma"
        (ngSubmit)="onSubmit()"
        class="form-horizontal form-material"
        id="loginform"
        action="index.html"
      >
        <div class="form-group m-t-40">
          <div class="col-xs-12">
            <mat-form-field appearance="fill" class="tws-w-full">
              <mat-label>Usuario</mat-label>
              <input matInput name="usuario" formControlName="usuario" />
            </mat-form-field>
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <mat-form-field appearance="fill" class="tws-w-full">
              <mat-label>Contraseña</mat-label>
              <input
                formControlName="pass"
                name="pass"
                matInput
                [type]="hide ? 'password' : 'text'"
              />
              <button
                type="button"
                mat-icon-button
                matSuffix
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide"
              >
                <mat-icon>{{
                  hide ? "visibility_off" : "visibility"
                }}</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <div class="checkbox checkbox-primary pull-left p-t-0">
              <mat-checkbox
                color="primary"
                formControlName="guardarSession"
                class="example-margin tws-text-md tws-flex tws-items-center"
                >Recordar contraseña</mat-checkbox
              >
            </div>
          </div>
        </div>
        <div class="form-group text-center m-t-20 tws-justify-center tws-flex">
          <div class="tws-w-7/12">
            <button
              class="tws-w-full tws-text-lg tws-p-3 hover:tws-bg-theme-quinary hover:tws-text-white tws-text-gray-800 tws-font-bold tws-rounded tws-bg-theme-primary text-uppercase"
              type="submit"
              [disabled]="bloquearBoton"
            >
              Ingresar
            </button>
          </div>
        </div>

        <div class="form-group m-b-0 tws-mt-8"></div>
      </form>
      <div *ngIf="cargando" class="tws-flex tws-justify-center tws-mt-12">
        <mat-spinner strokeWidth="4" class="!tws-text-blue-400"></mat-spinner>
      </div>
    </div>
  </div>
</section>
