<div
  *ngIf="cobro.estado == 'proceso' && cobro.tipoCobro != 7"
  class="tws-m-auto tws-w-full tws-rounded tws-overflow-hidden tws-shadow-md"
>
  <div
    class="tws-bg-theme-primary tws-text-gray-800 tws-px-4 tws-py-4 tws-font-bold tws-flex tws-items-center tws-text-lg tws-justify-between"
  >
    <div>Factura No. {{ cobro.factura }}</div>
    <div>
      <mat-icon
        (click)="descargarFirma()"
        class="receipt tws-cursor-pointer hover:tws-text-white"
        >receipt</mat-icon
      >
      <mat-icon
        (click)="descargarPdf()"
        class="picture-as-pdf tws-mx-3 tws-cursor-pointer hover:tws-text-white"
        >picture_as_pdf</mat-icon
      >
    </div>

    <mat-checkbox
      color="primary"
      [formControl]="seleccionarControl"
    ></mat-checkbox>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md">Fecha Factura</div>
    <div>{{ fechaFactura() }}</div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md">Fecha Cobro</div>
    <div>{{ fechaCobro() }}</div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md">Cliente</div>
    <div>{{ cobro.cliente }}</div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md">Tipo Cobro</div>
    <div>
      {{
        cobro.tipoCobro == 2
          ? "Bizum"
          : cobro.tipoCobro == 4
          ? "Tpv"
          : cobro.tipoCobro == 1
          ? "Cheque"
          : cobro.tipoCobro == 3
          ? "Transferencia"
          : cobro.tipoCobro == 6
          ? "Compensación"
          : cobro.tipoCobro == 7
          ? "Pasarela"
          : "Remesa"
      }}
    </div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md tws-w-1/2">Pagado</div>
    <div>
      {{ getImporte() }}
    </div>
  </div>
</div>

<div
  *ngIf="cobro.estado == 'completados' || cobro.tipoCobro == 7"
  class="tws-m-auto tws-w-full tws-rounded tws-overflow-hidden tws-shadow-md"
>
  <div
    class="tws-bg-theme-primary tws-text-gray-800 tws-px-4 tws-py-4 tws-font-bold tws-flex tws-items-center tws-text-lg tws-justify-between"
  >
    <div>Factura No. {{ cobro.factura }}</div>
    <div>
      <mat-icon
        (click)="descargarFirma()"
        class="receipt tws-cursor-pointer tws-ml-3"
        >receipt</mat-icon
      >
      <mat-icon
        (click)="descargarPdf()"
        class="picture-as-pdf tws-mx-3 tws-cursor-pointer"
        >picture_as_pdf</mat-icon
      >
    </div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md">Fecha Factura</div>
    <div>{{ fechaFactura() }}</div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md">Fecha Cobro</div>
    <div>{{ fechaCobro() }}</div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md">Cliente</div>
    <div>{{ cobro.cliente }}</div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md">Tipo Cobro</div>
    <div>
      {{
        cobro.tipoCobro == 2
          ? "Bizum"
          : cobro.tipoCobro == 4
          ? "Tpv"
          : cobro.tipoCobro == 1
          ? "Cheque"
          : cobro.tipoCobro == 3
          ? "Transferencia"
          : cobro.tipoCobro == 6
          ? "Compensación"
          : cobro.tipoCobro == 7
          ? "Pasarela"
          : "Remesa"
      }}
    </div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md tws-w-1/2">Pagado</div>
    <div>
      {{ getImporte() }}
    </div>
  </div>
</div>
