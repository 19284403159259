export class Mensaje {
  claveDiferente: string = 'Las contraseñas no coinciden';
  formularioInvalido: string = 'Verifique campos del formulario';
  alertAdvertencia: string = 'a';
  alertError: string = 'e';
  confirmacionCodigoSeguridad: string =
    'Estimado Cliente, verifique su teléfono e ingrese el codigo de verificación';
  usuarioExiste: string = 'El usuario ya existe';
  registroExitoso: string = 'Transacción exitosa';
  credencialesInvalidas: string = 'Credenciales Incorrectas...!';
  montoInvaido: string =
    'El abono seleccionado debe ser menor que las facturas seleccionadas..!';
  codigoWhatsappInvalido: string = 'El codigo introducido es incorrecto';
  montoCero: string = 'El monto a pagar no puede ser cero';
  maximoAbonos: string = 'No puede seleccionar mas de 1 abono.';
  mensajeDni: string = 'ingrese Dni';
  mensajeId: string = 'ingrese Su codigo de cliente';
  errorRegistroFacturas: string =
    'Error al intentar grabar la factura, verifique que no haya sido pagada';
  confirmacionPago: string = 'Está seguro que quiere pagar las facturas \n';
  transaccionExitosa: string = 'Transacción exitosa';
  confirmacionPagoRemesa: string =
    'Has seleccionado facturas por tipo de cobro ';
  confirmacionPagoRemesa1: string = ' por el valor de € '; //Has emitido un recibo por el valor de €
  pagosPorAntiguedad: string =
    'Los pagos deben realizarse por antiguedad de factura';
  errorNoPdfFactura: string = 'Error no se ha encontrado el pdf de la factura';
  errorNoFirmaFactura: string =
    'Error no se ha encontrado la firma de la factura';
}
