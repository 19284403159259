import { TipoUrlFacturaFirma } from './../../enums/TipoUrlFacturaFirmada.enum';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UrlFacturaFirmadaModel } from 'src/app/models/UrlFacturaFirmada.model';

@Component({
  selector: 'app-modal-firma',
  templateUrl: './modal-firma.component.html',
  styleUrls: ['./modal-firma.component.css'],
})
export class ModalFirmaComponent {
  @Input() urlFirma: UrlFacturaFirmadaModel;

  constructor(
    public dialogRef: MatDialogRef<ModalFirmaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.urlFirma = new UrlFacturaFirmadaModel();
    this.urlFirma.url = this.replaceAfterTempWithEncoded(data.urlFirma);
    this.urlFirma.tipo = data.urlFirma.includes('.pdf')
      ? TipoUrlFacturaFirma.PDF
      : TipoUrlFacturaFirma.IMG;
    /*if (this.urlFirma.tipo == TipoUrlFacturaFirma.PDF) {
      this.dialogRef.close({
        result: {
          url: this.urlFirma.url,
        },
      });
    }*/
  }

  replaceAfterTempWithEncoded = (url: string) => {
    const tempIndex = url.indexOf('/temp/') + 6; // add 6 to exclude '/temp/' itself

    const beforeTemp = url.substring(0, tempIndex);

    const afterTemp = url.substring(tempIndex);
    const endIndex = afterTemp.indexOf('?'); // find the index of the next question mark character
    const pdfString = afterTemp.substring(0, endIndex); // extract the string up to the question mark

    const encodedPdfString = btoa(pdfString); // encode the string in base64

    const newAfterTemp = encodedPdfString + afterTemp.substring(endIndex); // replace the pdfString with the encoded version

    return beforeTemp + newAfterTemp;
  };

  onNoClick(): void {
    this.dialogRef.close();
  }
}
