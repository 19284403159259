import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { UsuarioModel } from '../../models/usuario.model';
import { BehaviorSubject } from 'rxjs';
import { FacturaPendienteModel } from 'src/app/models/facturaPendiente.model';
import { FacturaPagarModel } from 'src/app/models/facturaPagar.model';
import { FacturaSeleccionadaModel } from 'src/app/models/facturaSelecionada.model';

@Injectable({
  providedIn: 'root',
})
export class PedidoService {
  private url = '';
  private baseUrlApi: String;
  private _error = new BehaviorSubject<string>('');

  private _cargando = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.url = environment.baseUrl;
    this.baseUrlApi = environment.baseUrlApi;
  }

  get cargando() {
    return this._cargando.asObservable();
  }

  get error() {
    return this._error.asObservable();
  }

  obtenerUrlPdf347(fechaDesde: Date, fechaHasta: Date, pendientes: number) {
    let fechaDesde1: String = new Date(fechaDesde)
      .toLocaleDateString('en-CA')
      .slice(0, 10);
    let fechaHasta1: String = new Date(fechaHasta)
      .toLocaleDateString('en-CA')
      .slice(0, 10);
    return this.http.get(
      `${this.baseUrlApi}clientes/pedidos/pdf-347/${fechaDesde1}/${fechaHasta1}/${pendientes}/1/0`
    );
  }

  obtenerUrlPdf347Detallado(
    fechaDesde: Date,
    fechaHasta: Date,
    pendientes: number
  ) {
    let fechaDesde1: String = new Date(fechaDesde)
      .toLocaleDateString('en-CA')
      .slice(0, 10);
    let fechaHasta1: String = new Date(fechaHasta)
      .toLocaleDateString('en-CA')
      .slice(0, 10);
    return this.http.get(
      `${this.baseUrlApi}clientes/pedidos/pdf-347-extendido/${fechaDesde1}/${fechaHasta1}/${pendientes}/1/0`
    );
  }

  obtenerUrlXlsx347(fechaDesde: Date, fechaHasta: Date, pendientes: number) {
    let fechaDesde1: String = new Date(fechaDesde)
      .toLocaleDateString('en-CA')
      .slice(0, 10);
    let fechaHasta1: String = new Date(fechaHasta)
      .toLocaleDateString('en-CA')
      .slice(0, 10);
    return this.http.get(
      `${this.baseUrlApi}clientes/pedidos/xlsx-347/${fechaDesde1}/${fechaHasta1}/${pendientes}/1/0`
    );
  }

  obtenerUrlXlsx347Detallado(
    fechaDesde: Date,
    fechaHasta: Date,
    pendientes: number
  ) {
    let fechaDesde1: String = new Date(fechaDesde)
      .toLocaleDateString('en-CA')
      .slice(0, 10);
    let fechaHasta1: String = new Date(fechaHasta)
      .toLocaleDateString('en-CA')
      .slice(0, 10);
    return this.http.get(
      `${this.baseUrlApi}clientes/pedidos/xlsx-347-extendido/${fechaDesde1}/${fechaHasta1}/${pendientes}/1/0`
    );
  }
}
