import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Mensaje } from 'src/app/mensajes/mensajes';
import { Alerta } from 'src/app/mensajes/helperAlerta';
import { MatDialog } from '@angular/material/dialog';
import { TarjetacobroComponent } from 'src/app/components/tarjetacobro/tarjetacobro.component';
import { FormControl, Validators } from '@angular/forms';
import _ from 'lodash';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { FacturaSeleccionadaModel } from 'src/app/models/facturaSelecionada.model';
import { CobrosEfectuadosService } from 'src/app/services/cobros-efectuados/cobros-efectuados.service';
import { CobroModel } from 'src/app/models/cobro.model';
import { ModalFirmaComponent } from 'src/app/components/modal-firma/modal-firma.component';
import { ViewportScroller } from '@angular/common';
import { PedidoService } from 'src/app/services/pedido/pedido.service';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css'],
})
export class PedidosComponent implements OnInit {
  private _alerta: Alerta;
  private _mensaje: Mensaje;
  panelOpenState: boolean = true;
  panelResumenState: boolean = false;
  fechaDesde: FormControl<Date>;
  fechaHasta: FormControl<Date>;
  numPedido: FormControl;
  importe: FormControl;
  tiposPedidos: FormControl<boolean>;
  cargando: boolean = false;
  pedidos: Array<CobroModel>;

  calcularInputControl: FormControl;

  @ViewChild(TarjetacobroComponent)
  componentProperty!: TarjetacobroComponent;

  constructor(
    private _pedidoService: PedidoService,
    public dialog: MatDialog,
    private scroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    this._mensaje = new Mensaje();
    this._alerta = new Alerta();
    let today = new Date();
    this.fechaDesde = new FormControl(new Date());
    this.fechaHasta = new FormControl(
      new Date(
        today.getTime() + ((5 - today.getDay() + 7) % 7) * 24 * 60 * 60 * 1000
      )
    );
    this.importe = new FormControl('');
    this.numPedido = new FormControl('');
    this.tiposPedidos = new FormControl(false);

    this._pedidoService.cargando.subscribe(
      (cargando) => (this.cargando = cargando)
    );

    this._pedidoService.error.subscribe((error) => {
      if (error) this._alerta.mostrarAlerta(error, 'error', '');
    });
    this.pedidos = [];
  }

  irApagar() {
    this.scroller.scrollToAnchor('seccionPagar');
  }

  buscar() {}

  formatMoney(val: any) {
    return UtilsService.formatMoney(val);
  }

  descargarPdf347() {
    this.cargando = true;

    this._pedidoService
      .obtenerUrlPdf347(
        this.fechaDesde.value,
        this.fechaHasta.value,
        Number(this.tiposPedidos.value)
      )
      .subscribe({
        next: (data: any) => {
          if (data.existeError || !data.data)
            this._alerta.mostrarAlerta(
              this._mensaje.errorNoFirmaFactura,
              this._mensaje.alertError
            );
          else {
            window.open(data.data.url, '_blank');
          }
        },
        error: (error: any) => {
          if (error.error.errors)
            this._alerta.mostrarAlerta(
              Object.values(error.error.errors).join(' \n'),
              'error',
              ''
            );
          else this._alerta.mostrarAlerta(error.error.error, 'error', '');
          this.cargando = false;
        },
        complete: () => {
          this.cargando = false;
        },
      });
  }

  descargarPdf347Detallado() {
    this.cargando = true;
    this._pedidoService
      .obtenerUrlPdf347Detallado(
        this.fechaDesde.value,
        this.fechaHasta.value,
        Number(this.tiposPedidos.value)
      )
      .subscribe({
        next: (data: any) => {
          if (data.existeError || !data.data)
            this._alerta.mostrarAlerta(
              this._mensaje.errorNoFirmaFactura,
              this._mensaje.alertError
            );
          else {
            window.open(data.data.url, '_blank');
          }
        },
        error: (error: any) => {
          if (error.error.errors)
            this._alerta.mostrarAlerta(
              Object.values(error.error.errors).join(' \n'),
              'error',
              ''
            );
          else this._alerta.mostrarAlerta(error.error.error, 'error', '');
          this.cargando = false;
        },
        complete: () => {
          this.cargando = false;
        },
      });
  }

  descargarXlsx347() {
    this.cargando = true;
   
    this._pedidoService
      .obtenerUrlXlsx347(
        this.fechaDesde.value,
        this.fechaHasta.value,
        Number(this.tiposPedidos.value)
      )
      .subscribe({
        next: (data: any) => {
          if (data.existeError || !data.data)
            this._alerta.mostrarAlerta(
              this._mensaje.errorNoFirmaFactura,
              this._mensaje.alertError
            );
          else {
            window.open(data.data.url, '_blank');
          }
        },
        error: (error: any) => {
          if (error.error.errors)
            this._alerta.mostrarAlerta(
              Object.values(error.error.errors).join(' \n'),
              'error',
              ''
            );
          else this._alerta.mostrarAlerta(error.error.error, 'error', '');
          this.cargando = false;
        },
        complete: () => {
          this.cargando = false;
        },
      });
  }

  descargarXlsx347Detallado() {
    this.cargando = true;
    this._pedidoService
      .obtenerUrlXlsx347Detallado(
        this.fechaDesde.value,
        this.fechaHasta.value,
        Number(this.tiposPedidos.value)
      )
      .subscribe({
        next: (data: any) => {
          if (data.existeError || !data.data)
            this._alerta.mostrarAlerta(
              this._mensaje.errorNoFirmaFactura,
              this._mensaje.alertError
            );
          else {
            window.open(data.data.url, '_blank');
          }
        },
        error: (error: any) => {
          if (error.error.errors)
            this._alerta.mostrarAlerta(
              Object.values(error.error.errors).join(' \n'),
              'error',
              ''
            );
          else this._alerta.mostrarAlerta(error.error.error, 'error', '');
          this.cargando = false;
        },
        complete: () => {
          this.cargando = false;
        },
      });
  }

  verFirma(urlFirma: string) {
    if (urlFirma.includes('.pdf')) {
      window.open(UtilsService.replaceAfterTempWithEncoded(urlFirma), '_blank');
    } else {
      const dialogRef = this.dialog.open(ModalFirmaComponent, {
        width: '1000px',
        height: '1000px',
        data: { urlFirma: urlFirma },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
  }
}
