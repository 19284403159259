import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { LoginComponent } from './pages/login/login.component';
import { APP_ROUTES } from './app.routes';
import { RegisterComponent } from './pages/login/register.component';
import { LoginGuardGuard } from './services/guards/login-guard.guard';
import { PagesModule } from './pages/pages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { JwtInterceptorInterceptor } from './interceptors/jwt-interceptor.interceptor';

import { PagarCobroComponent } from './components/pagar-cobro/pagar-cobro.component';
import { ProcesarCobroPasarelaComponent } from './components/procesar-cobro-pasarela/procesar-cobro-pasarela.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';
import { ModalFirmaComponent } from './components/modal-firma/modal-firma.component';
import { HttpErrorsInterceptor } from './interceptors/http-errors.interceptor';

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MatDatepickerModule,
    MatDialogModule,
    MatMomentDateModule,
    APP_ROUTES,
    FormsModule,
    MatCardModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatInputModule,
    HttpClientModule,
    ReactiveFormsModule,
    PagesModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatButtonModule,
    MatProgressBarModule,
    MatCheckboxModule,
    AppRoutingModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    PagarCobroComponent,
    ProcesarCobroPasarelaComponent,
    ModalFirmaComponent,
  ],
  providers: [
    LoginGuardGuard,
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorsInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es-ES',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
