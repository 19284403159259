import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  usuario: Observable<UsuarioModel>;
  @Input() isMenuOpened: boolean;
  @Output() isShowSidebar = new EventEmitter<boolean>();

  constructor(private router: Router, public UsuarioService: LoginService) {
    this.usuario = of(this.UsuarioService.datosUsuario());
  }

  public openMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;

    this.isShowSidebar.emit(this.isMenuOpened);
  }

  public signOut(): void {
    try {
      this.UsuarioService.Logout();
    } catch (error) {}
    //this.router.navigate([this.routers.LOGIN]);
  }
}
