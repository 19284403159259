import { CobroService } from 'src/app/services/cobro/cobro.service';
import { TipoCobro } from './../../enums/tipoCobro.enum';
import { Component, Inject, ElementRef, OnInit, Input } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
  ValidationErrors,
  FormControl,
} from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { FacturaPagarModel } from 'src/app/models/facturaPagar.model';
import { Alerta } from 'src/app/mensajes/helperAlerta';
import { CookieService } from 'ngx-cookie-service';

export interface DatosPagar {
  idPago: string;
  datosRedsys: any;
  cobros: any;
}

@Component({
  selector: 'app-procesar-cobro-pasarela',
  templateUrl: './procesar-cobro-pasarela.component.html',
  styleUrls: ['./procesar-cobro-pasarela.component.css'],
})
export class ProcesarCobroPasarelaComponent implements OnInit {
  selectedMethod: number;
  formRedsys: FormGroup;
  metodoPago: TipoCobro = TipoCobro.PASARELA;
  formAction: string;
  private _alerta: Alerta;
  datosPagar: DatosPagar;
  @Input() cobros: FacturaPagarModel[];

  step = 0;

  constructor(
    private el: ElementRef,
    private cService: CobroService,
    private cookieService: CookieService
  ) {
    this._alerta = new Alerta();
    this.datosPagar = { datosRedsys: '', idPago: '', cobros: '' };
  }

  ngOnInit(): void {
    this.setDatosPago();
    this.formRedsys = new FormGroup({
      merchantParameters: new FormControl(
        this.datosPagar.datosRedsys.Ds_MerchantParameters,
        Validators.required
      ),
      signature: new FormControl(
        this.datosPagar.datosRedsys.Ds_Signature,
        Validators.required
      ),
      signatureVersion: new FormControl(
        this.datosPagar.datosRedsys.Ds_SignatureVersion,
        Validators.required
      ),
    });
    this.formAction = this.datosPagar.datosRedsys.action;
  }

  setDatosPago() {
    this.cService.obtenerDatosPagoRedsys(this.cobros).subscribe({
      next: (data: any) => {
        this.datosPagar.datosRedsys = data.form;
        this.datosPagar.idPago = data.idPago;
        this.datosPagar.cobros = this.cobros;
        this.formRedsys.setValue({
          merchantParameters: data.form.Ds_MerchantParameters,
          signature: data.form.Ds_Signature,
          signatureVersion: data.form.Ds_SignatureVersion,
        });
        this.formAction = data.form.action;
      },
      error: (err: any) => {
        this.datosPagar.datosRedsys = '';
        this.datosPagar.idPago = '';
        this.datosPagar.cobros = '';
        if (err.error.errors)
          this._alerta.mostrarAlerta(
            Object.values(err.error.errors).join(' \n'),
            'error',
            ''
          );
        else this._alerta.mostrarAlerta(err.error.error, 'error', '');
      },
      complete: () => {},
    });
  }

  submitForm() {
    this.cookieService.set('id_pago', this.datosPagar.idPago, 1);
    // Simulate a click on the submit button
    const submitButton: HTMLButtonElement = this.el.nativeElement.querySelector(
      'button[type="submit"]'
    );
    if (submitButton) {
      //submitButton.click();
    }
  }

  onSubmit(e) {
    this.cookieService.set('id_pago', this.datosPagar.idPago, 1);
    e.target.submit();
    
  }
}
