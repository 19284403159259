import { TipoCobro } from './../../enums/tipoCobro.enum';
import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { ProcesarCobroPasarelaComponent } from '../procesar-cobro-pasarela/procesar-cobro-pasarela.component';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
  ValidationErrors,
  FormControl,
} from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { FacturaPagarModel } from 'src/app/models/facturaPagar.model';

export interface DialogData {
  importe: number;
  cobros: FacturaPagarModel[];
}

@Component({
  selector: 'app-pagar-cobro',
  templateUrl: './pagar-cobro.component.html',
  styleUrls: ['./pagar-cobro.component.css'],
})
export class PagarCobroComponent {
  selectedMethod: number;
  metodoPago: TipoCobro = TipoCobro.REMESA;
  cobrosRemesando: FacturaPagarModel[] = [];

  step = 0;

  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PagarCobroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.data.cobros.forEach((cobro: FacturaPagarModel) => {
      if (cobro.remesando) this.cobrosRemesando.push(cobro);
    });
  }

  firstStepForm = this._formBuilder.group({
    metodoPago: new FormControl(TipoCobro.REMESA, [Validators.required]),
  });

  selectMethod(method: number) {
    this.firstStepForm.patchValue({ metodoPago: method });
  }

  getImporteCobrado() {
    return this.data.importe;
  }

  formatMoney(value: number) {
    return value.toFixed(2) + ' €';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  pagar() {
    this.dialogRef.close({
      result: {
        metodoPago: this.firstStepForm.get('metodoPago').value,
      },
    });
  }
}
