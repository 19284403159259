<div class="tws-m-auto tws-rounded tws-overflow-hidden tws-shadow-md">
  <div
    class="tws-text-gray-800 tws-bg-theme-primary tws-px-4 tws-py-4 tws-font-bold tws-flex tws-items-center tws-text-lg tws-justify-between"
  >
    <div>Factura: {{ cobro.factura }}</div>
    <div>
      <mat-icon
        (click)="descargarFirma()"
        class="receipt tws-ml-3 tws-cursor-pointer hover:tws-text-white"
        >receipt</mat-icon
      >
      <mat-icon
        (click)="descargarPdf()"
        class="picture-as-pdf tws-mx-3 tws-cursor-pointer hover:tws-text-white"
        >picture_as_pdf</mat-icon
      >
    </div>

    <mat-checkbox
      color="primary"
      [formControl]="seleccionarControl"
    ></mat-checkbox>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md">Fecha Factura</div>
    <div>{{ fechaFactura() }}</div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md">Fecha Vencimiento</div>
    <div>{{ fechaVencimiento() }}</div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md">Importe</div>
    <div>{{ importeFactura() }}</div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md">Importe Procesando</div>
    <div>{{ importeProcesando() }}</div>
  </div>
  <div
    class="tws-flex tws-px-6 tws-py-4 tws-border-b-2 tws-border-gray-300 tws-items-center tws-justify-between"
  >
    <div class="tws-font-medium tws-text-md tws-w-1/2">Pagado</div>
    <div *ngIf="estaSeleccionado()" class="tws-w-1/2">
      <mat-form-field class="tws-text-sm tws-w-full tws-h-16">
        <input
          type="number"
          matInput
          [formControl]="priceControl"
          (change)="setImportePagar()"
        />
        <mat-error *ngIf="priceControl.hasError('required')">
          Importe requerido
        </mat-error>
        <mat-error *ngIf="priceControl.hasError('max')">
          Excede el importe
        </mat-error>
        <mat-icon matSuffix class="tws-text-sm">euro</mat-icon>
      </mat-form-field>
    </div>
    <div *ngIf="!estaSeleccionado()">
      {{ getImporte() }}
    </div>
  </div>
</div>
