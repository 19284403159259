import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { UsuarioModel } from '../../models/usuario.model';
import { BehaviorSubject } from 'rxjs';
import { FacturaPendienteModel } from 'src/app/models/facturaPendiente.model';
import { FacturaPagarModel } from 'src/app/models/facturaPagar.model';
import { FacturaSeleccionadaModel } from 'src/app/models/facturaSelecionada.model';

@Injectable({
  providedIn: 'root',
})
export class CobroService {
  private url = '';
  private baseUrlApi: String;
  private _error = new BehaviorSubject<string>('');
  private _cobrosPendientes = new BehaviorSubject<FacturaPendienteModel[]>([]);
  private _cobrosPagar = new BehaviorSubject<FacturaPagarModel[]>([]);
  private _facturasSeleccionadas = new BehaviorSubject<
    FacturaSeleccionadaModel[]
  >([]);
  private _cargando = new BehaviorSubject<boolean>(false);
  private _existeVencidas = new BehaviorSubject<boolean>(false);
  private _existeErrorSeleccion = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.url = environment.baseUrl;
    this.baseUrlApi = environment.baseUrlApi;
  }

  get cargando() {
    return this._cargando.asObservable();
  }

  get existeErrorSeleccion() {
    return this._existeErrorSeleccion.asObservable(); //
  }

  get existeVencidas() {
    return this._existeVencidas.asObservable(); //
  }

  get cobrosPendientes() {
    return this._cobrosPendientes.asObservable(); //
  }

  get cobrosPagar() {
    return this._cobrosPagar.asObservable(); //
  }

  get error() {
    return this._error.asObservable();
  }

  get facturasSeleccionadas() {
    return this._facturasSeleccionadas.asObservable();
  }

  estabalecerFacturasSeleccionadas(
    selccionadas: Array<FacturaSeleccionadaModel>
  ) {
    this._facturasSeleccionadas.next(selccionadas);
  }

  establecerExisteErrorSeleccion(valor: boolean) {
    this._existeErrorSeleccion.next(valor);
  }

  establecerCobrosPagar(cobros: Array<FacturaPagarModel>) {
    this._cobrosPagar.next(cobros);
  }

  establecerCobrosPendientes() {
    this._cargando.next(true);
    this.http.get(`${this.baseUrlApi}cobros/pendientes`).subscribe({
      next: (res: any) => {
        let cobrosPen = Object.values(res.data)
          .sort(
            (a: any, b: any) =>
              new Date(a.fechavenc).getTime() - new Date(b.fechavenc).getTime()
          )
          .map((cobro: any) => {
            let cobroPen = new FacturaPendienteModel();
            cobroPen.codCliente = cobro['cod_cliente'];
            cobroPen.importe = parseFloat(
              parseFloat(cobro['importe']).toFixed(3)
            );
            cobroPen.importeProcesando = cobro['importeProcesando'];
            cobroPen.factura = cobro['cod_factura'];
            cobroPen.pedido = cobro['Factura'];
            cobroPen.nombreCliente = cobro['nom_cliente'];
            cobroPen.representante = cobro['Representante'];
            cobroPen.fechaFactura = cobro['fec_factura'];
            cobroPen.fechaVencimiento = cobro['fechavenc'];
            cobroPen.fechaEnvio = cobro['fecha_env'];
            cobroPen.conError = cobro['conError'] == '0' ? false : true;
            cobroPen.procesando =
              cobro['facturaProcesandose'] == '0' ? false : true;
            cobroPen.aRemesar = cobro['A remesar'] == '0' ? false : true;
            return cobroPen;
          });
        this._cobrosPendientes.next(cobrosPen);
        this.checkExisteVencidas();
      },
      error: (err: any) => {
        if (err.error.errors)
          this._error.next(Object.values(err.error.errors).join(' \n'));
        else this._error.next(err.error.error);
        this._cargando.next(false);
      },
      complete: () => {
        this._cargando.next(false);
      },
    });
  }

  checkExisteVencidas() {
    const fechaActual = new Date();
    for (const cobro of this._cobrosPendientes.value) {
      let fecha = new Date(cobro.fechaVencimiento);

      if (fecha < fechaActual && !cobro.conError && cobro.importe > 0) {
        this._existeVencidas.next(true);
        break;
      }
    }
  }

  listarCobros(usuario: UsuarioModel) {
    return this.http.post(`${this.url}cobros-cliente`, {
      evento: 'LCR',
      codCliente: usuario.id,
      nombreCliente: null,
      nombreComercial: null,
      telefono: null,
    });
  }

  registrarCobro({
    metodoPago,
    noCheque,
    banco,
    tarjeta,
    cobros,
    fechaVencimiento,
  }) {
    return this.http.post(`${this.baseUrlApi}cobros`, {
      metodoPago,
      cobros: cobros.map((cobro) => [cobro.factura, cobro.importe]),
      noCheque: 0,
      banco: 0,
      tarjeta: 0,
      fechaVencimiento: new Date(),
    });
  }

  guardarCobros(usuario: UsuarioModel, facturasReverso: any) {
    return this.http.post(`${this.url}actualizar-factura`, {
      evento: 'I',
      idUsuario: usuario.id,
      tipoCobro: usuario.tipoCobro ?? 0,
      facturas: usuario.facturasPorPagar,
      facturasReverso: facturasReverso,
    });
  }

  obtenerUrlFactura(numFactura: string) {
    return this.http.get(`${this.url}seguimiento-diario/pdf/${numFactura}/0`);
  }

  obtenerUrlFirma(idDocumento: string) {
    return this.http.get(
      `${this.baseUrlApi}seguimiento-diario/firma/${idDocumento}/0`
    );
  }

  obtenerDatosPagoRedsys(cobros: FacturaPagarModel[]) {
    return this.http.post(`${this.baseUrlApi}redsys/payment`, {
      cobros,
    });
  }

  obtenerPagoRedsys(idOrden: string) {
    return this.http.get(`${this.baseUrlApi}redsys/${idOrden}`);
  }

  obtenerEnlacePagoRedsys(datosForm) {
    return this.http.post(`${datosForm.action}`, {
      Ds_MerchantParameters: datosForm.Ds_MerchantParameters,
      Ds_Signature: datosForm.Ds_Signature,
      Ds_SignatureVersion: datosForm.Ds_SignatureVersion,
    });
  }

  obtenerUrlPdfFacturasPendientes() {
    return this.http.get(
      `${this.baseUrlApi}clientes/facturas/pdf/1900-01-01/2300-01-01/true/0/0/0`
    );
  }
}
