import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { LoginGuardGuard } from '../services/guards/login-guard.guard';
import { CobrosComponent } from './cobros/cobros.component';
import { BuzonComponent } from './buzon/buzon.component';
import { CobrosEfectuadosComponent } from './cobros-efectuados/cobros-efectuados.component';
import { PagoRedsysKoComponent } from './pago-redsys-ko/pago-redsys-ko.component';
import { PagoRedsysOkComponent } from './pago-redsys-ok/pago-redsys-ok.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { DatosPara347Component } from './datos-para347/datos-para347.component';

const pagesRoutes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [LoginGuardGuard],
    children: [
      {
        path: 'cobros',
        component: CobrosComponent,
        data: { titulo: 'Cobros' },
      },
      {
        path: 'cobros/efectuados',
        component: CobrosEfectuadosComponent,
        data: { titulo: 'Cobros Efectuados' },
      },
      {
        path: 'buzon',
        component: BuzonComponent,
        data: { titulo: 'Buzon Entrada' },
      },
      {
        path: 'pago-completado',
        component: PagoRedsysOkComponent,
        data: { titulo: 'Pago Completado' },
      },
      {
        path: 'pago-fallido',
        component: PagoRedsysKoComponent,
        data: { titulo: 'Pago Fallido' },
      },
      {
        path: 'datos-para-347',
        component: DatosPara347Component,
        data: { titulo: 'datos-para-347' },
      },
      { path: '', redirectTo: '/cobros', pathMatch: 'full' },
    ],
  },
];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
