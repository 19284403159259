<app-layout>
  <div
    class="tws-flex tws-justify-center tws-flex-wrap tws-items-center tws-h-screen tws-bg-gray-100"
    *ngIf="loading"
  >
    <div>
      <mat-spinner></mat-spinner>
      <div class="tws-text-center tws-mt-5">Verificando Pago</div>
    </div>
  </div>

  <div
    class="tws-flex tws-justify-center tws-h-screen tws-items-center"
    *ngIf="!loading && !datosPago"
  >
    <div class="tws-text-center tws-text-lg tws-font-semibold">
      No se ha encontrado el pago: Consulte con soporte
    </div>
  </div>

  <div
    class="tws-flex tws-justify-center tws-h-screen"
    *ngIf="!loading && datosPago"
  >
    <mat-card class="tws-w-full">
      <mat-card-header
        class="tws-w-full tws-bg-yellow-300 tws-flex tws-justify-center tws-px-9 tws-pt-16 tws-pb-16 tws-flex-wrap tws-flex-col tws-mb-5"
      >
        <div class="tws-flex tws-justify-center tws-my-5">
          <mat-icon
            class="check_circle tws-bg-green-600 tws-text-white !tws-w-16 !tws-h-16 tws-text-4xl !tws-flex tws-justify-center tws-items-center tws-rounded-full"
            >check_circle</mat-icon
          >
        </div>
        <mat-card-title class="tws-text-center tws-text-2xl"
          >Su pago se ha completado correctamente</mat-card-title
        >
      </mat-card-header>
      <mat-card-content
        class="tws-flex tws-flex-col tws-items-center tws-justify-center tws-shadow-md"
      >
        <div class="tws-my-10 tws-text-lg tws-w-8/12 md:tws-w-5/12">
          <div class="tws-w-full">
            <div
              class="tws-flex tws-justify-between tws-border-b-2 tws-pb-2 tws-mb-2"
            >
              <div>Total cobrado</div>
              <div class="tws-text-2xl tws-font-semibold">
                {{ importeTotal.toFixed(2) }}€
              </div>
            </div>
            <div
              class="tws-flex tws-justify-between tws-border-b-2 tws-pb-2 tws-mb-2"
            >
              <div>Identificador de orden</div>
              <div>{{ datosPago.order_id }}</div>
            </div>
            <div
              class="tws-flex tws-justify-between tws-border-b-2 tws-pb-2 tws-mb-2"
            >
              <div>Identificador de cobro</div>
              <div>{{ datosPago.cobro_id }}</div>
            </div>
          </div>
          <div class="tws-mt-8">
            <h2 class="tws-text-xl tws-font-semibold">Cobros</h2>

            <div *ngFor="let cobro of cobros" class="tws-mt-5">
              <div
                class="tws-flex tws-justify-between tws-border-b-2 tws-pb-2 tws-mb-2"
              >
                <div>Factura</div>
                <div>{{ cobro[0] }}</div>
              </div>
              <div
                class="tws-flex tws-justify-between tws-border-b-2 tws-pb-2 tws-mb-2"
              >
                <div>Cobrado</div>
                <div>{{ cobro[1].toFixed(2) }}€</div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="tws-flex tws-justify-center tws-mt-12">
        <button
          mat-raised-button
          color="primary"
          class="tws-text-xl"
          (click)="volver()"
        >
          Volver
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</app-layout>
