import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FacturaPagarModel } from 'src/app/models/facturaPagar.model';
import { FacturaPendienteModel } from 'src/app/models/facturaPendiente.model';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { FormControl, Validators } from '@angular/forms';
import { FacturaSeleccionadaModel } from 'src/app/models/facturaSelecionada.model';
import { CobroService } from 'src/app/services/cobro/cobro.service';
import { Alerta } from 'src/app/mensajes/helperAlerta';
import { Mensaje } from 'src/app/mensajes/mensajes';
import { TipoUrlFacturaFirma } from 'src/app/enums/TipoUrlFacturaFirmada.enum';

@Component({
  selector: 'app-tarjetacobro',
  templateUrl: './tarjetacobro.component.html',
  styleUrls: ['./tarjetacobro.component.css'],
})
export class TarjetacobroComponent implements OnInit {
  @Input() cobro: FacturaPendienteModel;
  @Input() cobrosPagar: Array<FacturaPagarModel>;
  @Input() existeVencidos: boolean;
  @Input() existeErrorSeleccion: boolean;

  @Output() seleccion = new EventEmitter<FacturaSeleccionadaModel>();
  @Output() urlFirma = new EventEmitter<string>();
  //@Input() selecionarFactura: (factura: FacturaSeleccionadaModel, agregar: boolean) => string;

  priceControl: FormControl;
  seleccionarControl: FormControl;
  private _alerta: Alerta;
  private _mensaje: Mensaje;

  constructor(private _cobrosService: CobroService) {
    this._cobrosService.existeErrorSeleccion.subscribe((value) => {
      if (value) {
        this.seleccionarControl.setValue(false);
      }
    });
  }

  ngOnInit() {
    this._mensaje = new Mensaje();
    this._alerta = new Alerta();

    this.priceControl = new FormControl(this.cobro.importe, [
      Validators.required,
      Validators.max(this.cobro.importe),
    ]);
    this.seleccionarControl = new FormControl(false);

    this.seleccionarControl.valueChanges.subscribe((value) => {
      if (value) {
        this.seleccion.emit({
          factura: this.cobro.factura,
          noEntrada: this.cobro.noEntrada,
          seleccionada: true,
        });
      } else
        this.seleccion.emit({
          factura: this.cobro.factura,
          noEntrada: this.cobro.noEntrada,
          seleccionada: false,
        });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.existeErrorSeleccion &&
      changes.existeErrorSeleccion.currentValue &&
      this.seleccionarControl.value
    ) {
      this.seleccionarControl.setValue(false, { emitEvent: false });
    }

    if (
      changes.cobrosPagar &&
      changes.cobrosPagar.currentValue &&
      this.priceControl
    ) {
      changes.cobrosPagar.currentValue.map((cobro) => {
        if (cobro.factura == this.cobro.factura) {
          this.priceControl.setValue(cobro.importe.toFixed(2));
          this.seleccionarControl.setValue(true, { emitEvent: false });
        }
      });
    }
  }

  getIndexValorPagar(cod_factura: string) {
    let i = 0;
    for (const cobroPagar of this.cobrosPagar) {
      if (cobroPagar.factura == cod_factura) break;
      i++;
    }
    return i;
  }

  getImporte() {
    let c = this.cobrosPagar.filter(
      (cobro) => cobro.factura == this.cobro.factura
    );
    if (c.length > 0) return c[0].importe;
    else return 0;
  }

  setImportePagar() {
    let cobros = this.cobrosPagar.map((cobro) => {
      if (cobro.factura == this.cobro.factura)
        cobro.importe = this.priceControl.value;
      return cobro;
    });
    this._cobrosService.establecerCobrosPagar(cobros);
  }

  getValorPagado() {
    //return UtilsService.formatMoney(this.getPagado(parseFloat(this.cobro.factura)))
  }

  estaSeleccionado() {
    for (const facturaPagar of this.cobrosPagar) {
      if (facturaPagar.factura == this.cobro.factura) {
        //this.priceControl.setValue(this.getImporte().toFixed(2));
        return true;
      }
    }
    return false;
  }

  fechaVencimiento() {
    return UtilsService.formatDate(this.cobro.fechaVencimiento);
  }

  fechaFactura() {
    return UtilsService.formatDate(this.cobro.fechaFactura);
  }

  importeFactura() {
    return UtilsService.formatMoney(this.cobro.importe);
  }

  importeProcesando() {
    return UtilsService.formatMoney(this.cobro.importeProcesando);
  }

  replaceAfterTempWithEncoded = (url: string) => {
    const tempIndex = url.indexOf('/temp/') + 6; // add 6 to exclude '/temp/' itself

    const beforeTemp = url.substring(0, tempIndex);

    const afterTemp = url.substring(tempIndex);
    const endIndex = afterTemp.indexOf('?'); // find the index of the next question mark character
    const pdfString = afterTemp.substring(0, endIndex); // extract the string up to the question mark

    const encodedPdfString = btoa(pdfString); // encode the string in base64

    const newAfterTemp = encodedPdfString + afterTemp.substring(endIndex); // replace the pdfString with the encoded version

    return beforeTemp + newAfterTemp;
  };

  descargarPdf() {
    this._cobrosService.obtenerUrlFactura(this.cobro.pedido).subscribe({
      next: (data: any) => {
        if (data.existeError || !data.data)
          this._alerta.mostrarAlerta(
            this._mensaje.errorNoPdfFactura,
            this._mensaje.alertError
          );
        else {
          window.open(
            this.replaceAfterTempWithEncoded(data.data.url),
            '_blank'
          );
        }
      },
      error: (error: any) => {
        if (error.error.errors)
          this._alerta.mostrarAlerta(
            Object.values(error.error.errors).join(' \n'),
            'error',
            ''
          );
        else this._alerta.mostrarAlerta(error.error.error, 'error', '');
      },
      complete: () => {},
    });
  }

  descargarFirma() {
    this._cobrosService.obtenerUrlFirma(this.cobro.pedido).subscribe({
      next: (data: any) => {
        if (data.existeError || !data.data)
          this._alerta.mostrarAlerta(
            this._mensaje.errorNoFirmaFactura,
            this._mensaje.alertError
          );
        else {
          if (data.data.url.includes('.pdf')) {
            window.open(
              this.replaceAfterTempWithEncoded(data.data.url),
              '_blank'
            );
          } else this.urlFirma.emit(data.data.url);
        }
      },
      error: (error: any) => {
        if (error.error.errors)
          this._alerta.mostrarAlerta(
            Object.values(error.error.errors).join(' \n'),
            'error',
            ''
          );
        else this._alerta.mostrarAlerta(error.error.error, 'error', '');
      },
      complete: () => {},
    });
  }
}
