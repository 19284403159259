import { HeaderModule } from './header/header.module';
import { NgModule } from '@angular/core';
import { NopagefoundComponent } from '../shared/nopagefound/nopagefound.component';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { BreadcrumsComponent } from '../shared/breadcrums/breadcrums.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { LayoutComponent } from './layout/layout.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
  declarations: [
    SidebarComponent,
    BreadcrumsComponent,
    NopagefoundComponent,
    LayoutComponent,
  ],
  exports: [
    HeaderModule,
    HeaderModule,
    SidebarComponent,
    BreadcrumsComponent,
    NopagefoundComponent,
    LayoutComponent,
  ],
  imports: [
    RouterModule,
    MatSidenavModule,
    CommonModule,
    MatListModule,
    HeaderModule,
    MatIconModule,
  ],
})
export class SharedModule {}
