import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  menu: any = [
    {
      titulo: 'Menu',
      icono: 'mdi mdi-view-headline',
      submenu: [
        { titulo: 'Cobros', url: '/cobros', icono: 'euro' },
        { titulo: 'Datos para 347', url: '/datos-para-347', icono: 'grading' },
        { titulo: 'Buzónde Entrada', url: '/buzon', icono: 'mail' },
      ],
    },
  ];

  constructor() {}
}
