<app-layout>
  <div class="tws-mt-5 tws-max-w-full">
    <div class="tws-w-full">
      <div class="card tws-min-h-screen">
        <div class="card-body">
          <h4 class="card-title tws-py-5 tws-text-lg">Buzón de Entrada</h4>

          <div
            class="tws-mb-8 md:tws-mb-5 tws-grid tws-grid-cols-1 md:tws-grid-cols-2 lg:tws-grid-cols-5 tws-gap-4"
          >
            <div class="">
              <mat-form-field
                appearance="fill"
                class="tws-max-w-full tws-w-full"
              >
                <mat-label>Fecha Desde</mat-label>
                <mat-hint></mat-hint>
                <input
                  matInput
                  [matDatepicker]="picker"
                  [formControl]="dateDesde"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="">
              <mat-form-field
                appearance="fill"
                class="tws-max-w-full tws-w-full"
              >
                <mat-label>Fecha Hasta</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker1"
                  [formControl]="dateHasta"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-sm-4">
              <button
                mat-fab
                color="accent"
                class="!tws-py-6 tws-mr-4"
                (click)="buscar()"
              >
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </div>

          <div class="table-responsive m-t-40">
            <table id="myTable" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Num. Cobro</th>
                  <th>Fecha</th>
                  <th>Observacion</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let obj of _buzon; let i = index">
                  <td class="title">{{ obj.cobro }}</td>
                  <td>{{ obj.fechaCobro }}</td>
                  <td>{{ obj.mensaje }}</td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="cargando" class="tws-flex tws-justify-center tws-mt-12">
              <mat-spinner
                strokeWidth="4"
                class="!tws-text-blue-400"
              ></mat-spinner>
            </div>
            <div
              *ngIf="_buzon.length === 0 && cargando == false"
              class="tws-flex tws-justify-center tws-mt-12"
            >
              <div>No se ha encontrado resultados</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-layout>
